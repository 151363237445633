import { FC, useEffect } from 'react';
import { Stage, Layer, Image } from 'react-konva';
import useImage from 'use-image';

import useStageInteractions from 'hooks/editor/useStageInteractions';
import useDefineOrientation from 'hooks/editor/useDefineOrientation';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import imageSizeService from 'utils/editor/sizes/ImageSizeService';
import shapePointsService from 'utils/editor/coordinates/ShapePointsService';
import getImageUrlBySize from 'utils/gallery/getImageUrlBySize';

import IMAGE_SIZE_INDEXES from 'constants/gallery/imageSizeIndexes';
import { STAGE_PADDING } from 'constants/editor/general';

import { setOriginalCroppedImageSize } from 'redux/editor/editorReducer';
import { activeImageObjectSelector } from 'redux/gallery';
import {
  croppedImageURLSelector,
  originalCroppedImageSizeSelector,
} from 'redux/editor';

const CanvasMobile: FC = () => {
  const { stageRef, croppedImageRef, mainLayerRef } = useEditorRefs();

  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const originalCroppedImageSize = useAppSelector(
    originalCroppedImageSizeSelector,
  );
  const croppedImageUrl = useAppSelector(croppedImageURLSelector);

  const croppedImage = croppedImageRef.current;

  const dispatch = useAppDispatch();

  const [activeImage] = useImage(
    croppedImageUrl ||
      getImageUrlBySize(activeImageObject, IMAGE_SIZE_INDEXES.LARGE),
  );

  // setup environment hooks
  useStageInteractions();
  useDefineOrientation();

  // set original product image size
  useEffect(() => {
    if (!activeImage || croppedImageUrl) return;

    const originalImageSize = {
      width: activeImage.naturalWidth,
      height: activeImage.naturalHeight,
    };

    dispatch(setOriginalCroppedImageSize(originalImageSize));
  }, [dispatch, activeImage, croppedImageUrl]);

  // setup cropped image size
  useEffect(() => {
    const stage = stageRef.current;

    if (!stage || !croppedImage || !originalCroppedImageSize) return;

    const responsiveImageSize =
      imageSizeService.getResponsiveImageSizeByOrientation(
        originalCroppedImageSize,
        stage.size(),
        STAGE_PADDING,
      );

    croppedImage.size(responsiveImageSize);

    const centerImagePosition =
      shapePointsService.getShapeAlignmentPoint(
        stage.size(),
        responsiveImageSize,
      );

    croppedImage.setAbsolutePosition(centerImagePosition);
  }, [
    stageRef,
    croppedImageRef,
    originalCroppedImageSize,
    croppedImage,
  ]);

  useEffect(() => {
    const stage = stageRef.current;

    if (!stage || !croppedImageUrl || !activeImage || !croppedImage)
      return;

    const originalImageSize = {
      width: activeImage.naturalWidth,
      height: activeImage.naturalHeight,
    };

    const responsiveImageSize =
      imageSizeService.getResponsiveImageSizeByOrientation(
        originalImageSize,
        stage.size(),
        STAGE_PADDING,
      );

    croppedImage.size(responsiveImageSize);

    const centerImagePosition =
      shapePointsService.getShapeAlignmentPoint(
        stage.size(),
        responsiveImageSize,
      );

    croppedImage.setAbsolutePosition(centerImagePosition);
  }, [
    stageRef,
    activeImage,
    croppedImageUrl,
    croppedImageRef,
    croppedImage,
  ]);

  return (
    <Stage ref={stageRef}>
      <Layer ref={mainLayerRef}>
        <Image ref={croppedImageRef} image={activeImage} />
      </Layer>
    </Stage>
  );
};

export default CanvasMobile;
