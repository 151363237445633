import { FC, useEffect } from 'react';
import cn from 'classnames';

import useSelectProductOptions from 'hooks/sidePanel/useSelectProductOptions';
import useAppSelector from 'hooks/redux/useAppSelector';
import useOrientation from 'hooks/editor/useOrientation';
import useEditMode from 'hooks/editor/useEditMode';

import { activeImageObjectSelector } from 'redux/gallery';
import {
  isSquareSizeSelector,
  orientationProductOptionsSelector,
  selectedProductOrientationSelector,
  selectedProductSizeOptionSelector,
} from 'redux/sidePanel';

import productOptionsService from 'utils/sidePanel/ProductOptionService';
import OPTION_STATES from 'constants/sidePanel/optionStates';
import { IProductOption } from 'types/general';

import OrientationOption from './OrientationOption';

const Orientation: FC = () => {
  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const isSquareSize = useAppSelector(isSquareSizeSelector);
  const selectedProductOrientation = useAppSelector(
    selectedProductOrientationSelector,
  );
  const orientationProductOptions = useAppSelector(
    orientationProductOptionsSelector,
  );
  const selectedProductSize = useAppSelector(
    selectedProductSizeOptionSelector,
  );

  const [isEditMode] = useEditMode();
  const changeOrientationHandler = useOrientation();

  const isDisabled =
    !activeImageObject || !selectedProductSize || !isEditMode;

  const selectProductOption = useSelectProductOptions();

  const reversedOrientation = [
    ...orientationProductOptions,
  ].reverse();

  const filteredOrientation = isSquareSize
    ? reversedOrientation.filter(
        (productOption) => productOption.productOptionId !== 'V',
      )
    : reversedOrientation;

  const orientationProductOptionsList = filteredOrientation.map(
    (productOption: IProductOption) => {
      const { productOptionId, displayName } = productOption;

      const changeOrientation = () => {
        if (isDisabled) return;

        const size = selectedProductSize?.size;

        if (!size) return;

        selectProductOption(productOption);
        changeOrientationHandler(productOptionId, size);
      };

      const isChecked = productOptionsService.getOptionStateValue(
        productOption.optionsStates,
        OPTION_STATES.IS_SELECTED,
      );

      return (
        <OrientationOption
          key={productOptionId}
          isChecked={isChecked}
          isDisabled={isDisabled}
          displayName={displayName}
          productOptionId={productOptionId}
          changeOrientation={changeOrientation}
        />
      );
    },
  );

  useEffect(() => {
    if (!isSquareSize || !selectedProductOrientation) return;

    const { productOptionId } = selectedProductOrientation;

    if (productOptionId === 'H') return;

    const productOption = filteredOrientation[0];
    selectProductOption(productOption);
  }, [
    filteredOrientation,
    isSquareSize,
    selectProductOption,
    selectedProductOrientation,
  ]);

  return (
    <div className="orientation">
      <span className="orientation-title">Orientation</span>
      <div
        className={cn('orientation-container', {
          'is-disabled': isDisabled,
        })}
      >
        {orientationProductOptionsList}
      </div>
    </div>
  );
};

export default Orientation;
