/* eslint-disable react/prop-types */
import { Range } from 'react-range';
import { FC, useState } from 'react';

import { ISliderProps } from 'types/slider';

export const TicksSlider: FC<ISliderProps> = ({
  step = 1,
  min,
  max,
  value,
  onChange,
}) => {
  const getRangeMarkStyle = (props: any, index: number) => ({
    ...props.style,
    backgroundColor: index * step < value ? '#ffc600' : '#b7b7b7',
  });

  return (
    <div className="range-tick-container">
      <Range
        values={[value]}
        step={step}
        min={min}
        max={max}
        onChange={(el) => onChange(el[0])}
        renderMark={({ props, index }) => (
          <div
            className="range-mark"
            {...props}
            style={getRangeMarkStyle(props, index)}
          />
        )}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className="range-track"
            style={{
              ...props.style,
            }}
          >
            <div className="range-line" ref={props.ref}>
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            className="range-thumb"
            {...props}
            style={{
              ...props.style,
            }}
          />
        )}
      />
      <output className="range-value">{value}</output>
    </div>
  );
};
