import { SIDE_PANEL_ROUTES } from 'constants/routes/app/routes';

import { productCategories } from './productCategories';

const [acrylicPrint, metalPrint, canvasWrap, acrylicBlock] =
  productCategories;
const { METAL_PRINT, ACRYLIC_PRINT, CANVAS_WRAP, ACRYLIC_BLOCK } =
  SIDE_PANEL_ROUTES;

const productCategoriesDependOnRoutes = {
  [METAL_PRINT]: metalPrint.productCategoryType,
  [ACRYLIC_PRINT]: acrylicPrint.productCategoryType,
  [CANVAS_WRAP]: canvasWrap.productCategoryType,
  [ACRYLIC_BLOCK]: acrylicBlock.productCategoryType,
};

export default productCategoriesDependOnRoutes;
