import { useCallback, useContext } from 'react';

import usePrevValues from 'hooks/editor/intersections/usePrevValues';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';

import shapePointsService from 'utils/editor/coordinates/ShapePointsService';
import scaleFromCenterPoint from 'utils/editor/scale/scaleFromCenterPoint';
import imageSizeService from 'utils/editor/sizes/ImageSizeService';
import calcProductSize from 'utils/sidePanel/calcProductSize';

import { STAGE_PADDING } from 'constants/editor/general';
import { ZOOM_VALUES } from 'constants/editor/zoom';

import {
  CropUpdateContext,
} from 'context/contexts/editor/canvas';
import { ISizeString } from 'types/general';
import useSetupColorWraps from 'hooks/editor/view/useSetupColorWraps';

type Result = (size: ISizeString) => void;

const useProductSize = (): Result => {
  const { stageRef, mainLayerRef, croppedImageRef, imageWrapperRef } =
    useEditorRefs();
  const cropUpdate = useContext(CropUpdateContext);

  const { setPrevValues } = usePrevValues();
  const { setupColorWraps } = useSetupColorWraps();

  const applyProductSize = useCallback(
    (size: ISizeString) => {
      const mainLayer = mainLayerRef.current;
      const imageWrapper = imageWrapperRef.current;
      const croppedImage = croppedImageRef.current;
      const stage = stageRef.current;

      if (!mainLayer || !croppedImage || !stage || !imageWrapper)
        return;

      const { width: widthInches, height: heightInches } = size;

      const productSize = {
        width: +widthInches,
        height: +heightInches,
      };

      const newProductSize = calcProductSize(
        productSize,
        stage.size(),
        stage.size(),
      );

      const currentProductSize = croppedImage.size();

      // change size params considering orientation
      const isVertical = imageSizeService.isVerticalShape(
        currentProductSize,
      );

      const newProductSizeConsideringOrientation = {
        width: isVertical
          ? newProductSize.width
          : newProductSize.height,
        height: isVertical
          ? newProductSize.height
          : newProductSize.width,
      };

      croppedImage.size(newProductSizeConsideringOrientation);

      // temporary align of the product in the middle
      const centerPosition =
        shapePointsService.getShapeAlignmentPoint(
          stage.size(),
          newProductSizeConsideringOrientation,
        );

      croppedImage.setAbsolutePosition(centerPosition);

      const cropScaleXDiff =
        (isVertical ? newProductSize.width : newProductSize.height) /
        currentProductSize.width;

      const cropScaleDiff =
        (isVertical ? newProductSize.height : newProductSize.width) /
        currentProductSize.height;

      // scale crop border to product size
      const nextScaleX = ZOOM_VALUES.START_ZOOM - cropScaleXDiff;

      const nextScaleY = ZOOM_VALUES.START_ZOOM - cropScaleDiff;

      const cropScale = +Math.min(nextScaleX, nextScaleY).toFixed(6);

      scaleFromCenterPoint({
        scaleObject: imageWrapper,
        isZoomOut: true,
        scaleX: cropScale,
      });

      const mainLayerScale = mainLayer.scale();

      if (!mainLayerScale) return;

      // scale product layer to full view
      const newLayerScaleX =
        (stage.width() - STAGE_PADDING) /
        (imageWrapper.width() * imageWrapper.scaleX());

      const newLayerScaleY =
        (stage.height() - STAGE_PADDING) /
        (imageWrapper.height() * imageWrapper.scaleY());

      const layerScale = +Math.min(
        newLayerScaleX,
        newLayerScaleY,
      ).toFixed(3);

      scaleFromCenterPoint({
        scaleObject: mainLayer,
        isZoomOut: false,
        scaleX: layerScale - ZOOM_VALUES.START_ZOOM,
      });

      cropUpdate();
      setPrevValues();

      setupColorWraps();
    },
    [
      setupColorWraps,
      cropUpdate,
      croppedImageRef,
      imageWrapperRef,
      mainLayerRef,
      setPrevValues,
      stageRef,
    ],
  );

  return applyProductSize;
};

export default useProductSize;
