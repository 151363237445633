import { useCallback, useEffect } from 'react';

import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import { originalCroppedImageSizeSelector } from 'redux/editor';
import { setBasedOrientationId } from 'redux/gallery';
import { sessionIdSelector } from 'redux/auth';
import {
  getProductAsync,
  isSelectedOrientationSelector,
  productQuantitySelector,
  selectedProductSizeOptionSelector,
} from 'redux/sidePanel';

import imageSizeService from 'utils/editor/sizes/ImageSizeService';
import { ORIENTATION } from 'constants/sidePanel/general';
import { ISize } from 'types/editor';

const useDefineOrientation = (): void => {
  const productQuantity = useAppSelector(productQuantitySelector);
  const sessionId = useAppSelector(sessionIdSelector);
  const selectedProductSizeOption = useAppSelector(
    selectedProductSizeOptionSelector,
  );
  const originalCroppedImageSize = useAppSelector(
    originalCroppedImageSizeSelector,
  );
  const isSelectedOrientation = useAppSelector(
    isSelectedOrientationSelector,
  );

  const dispatch = useAppDispatch();

  const setDefaultOrientation = useCallback(
    (size: ISize) => {
      if (!sessionId) return;

      const isVertical = imageSizeService.isVerticalShape(size);

      const orientation = isVertical ? 'V' : 'H';

      const selectedOrientationOption = {
        productOptionId: orientation,
        productOptionGroupId: ORIENTATION,
      };

      const requestBodyParam = {
        quantity: productQuantity,
        selectedOptions: [selectedOrientationOption],
      };

      dispatch(setBasedOrientationId(orientation));

      dispatch(getProductAsync({ requestBodyParam }));
    },
    [dispatch, sessionId, productQuantity],
  );

  // set default orientation
  useEffect(() => {
    if (!originalCroppedImageSize || isSelectedOrientation) return;
    setDefaultOrientation(originalCroppedImageSize);
  }, [
    selectedProductSizeOption,
    isSelectedOrientation,
    originalCroppedImageSize,
    setDefaultOrientation,
  ]);
};

export default useDefineOrientation;
