import { createContext } from 'react';

import {
  ColorWrapsType,
  EditorRefsType,
} from 'types/editor/refTypes';

export const EditorRefsContext = createContext<EditorRefsType | null>(
  null,
);

export const CropUpdateContext = createContext(() => {});
export const CropStartContext = createContext(() => {});

export const ColorWrapsRefContext =
  createContext<ColorWrapsType>([]);
