import { useCallback, useContext } from 'react';

import shapePointsService from 'utils/editor/coordinates/ShapePointsService';
import imageSizeService from 'utils/editor/sizes/ImageSizeService';
import scaleFromCenterPoint from 'utils/editor/scale/scaleFromCenterPoint';

import { STAGE_PADDING } from 'constants/editor/general';
import { ZOOM_VALUES } from 'constants/editor/zoom';

import {
  originalCroppedImageSizeSelector,
  visualRotationSelector,
} from 'redux/editor';
import { setVisualRotation } from 'redux/editor/editorReducer';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import { CropUpdateContext } from 'context/contexts/editor/canvas';
import { Void } from 'types/general';

const useResetEditor = (): Void => {
  const cropUpdate = useContext(CropUpdateContext);

  const { stageRef, mainLayerRef, croppedImageRef, imageWrapperRef } =
    useEditorRefs();

  const originalCroppedImageSize = useAppSelector(
    originalCroppedImageSizeSelector,
  );
  const visualRotation = useAppSelector(visualRotationSelector);

  const dispatch = useAppDispatch();

  const resetEditor = useCallback(() => {
    const imageWrapper = imageWrapperRef.current;
    const croppedImage = croppedImageRef.current;
    const mainLayer = mainLayerRef.current;
    const stage = stageRef.current;

    if (
      !stage ||
      !mainLayer ||
      !croppedImage ||
      !imageWrapper ||
      !originalCroppedImageSize
    ) {
      return;
    }

    const scaleDiff = mainLayer.scaleX() - ZOOM_VALUES.START_ZOOM;

    scaleFromCenterPoint({
      scaleObject: mainLayer,
      isZoomOut: true,
      scaleX: scaleDiff,
    });

    const responsiveImageSize =
      imageSizeService.getResponsiveImageSizeByOrientation(
        originalCroppedImageSize,
        stage.size(),
        STAGE_PADDING,
      );

    croppedImage.size(responsiveImageSize);

    const centeredImagePos =
      shapePointsService.getShapeAlignmentPoint(
        stage.size(),
        responsiveImageSize,
      );

    croppedImage.setAbsolutePosition(centeredImagePos);

    imageWrapper.rotation(0);
    imageWrapper.scale({ x: 1, y: 1 });
    imageWrapper.setAbsolutePosition(centeredImagePos);

    // if (visualRotation !== 0) {
    //   dispatch(setVisualRotation(visualRotation));
    // }

    dispatch(setVisualRotation(0));

    cropUpdate();
  }, [
    stageRef,
    dispatch,
    cropUpdate,
    mainLayerRef,
    croppedImageRef,
    imageWrapperRef,
    originalCroppedImageSize,
  ]);

  return resetEditor;
};

export default useResetEditor;
