import { useCallback, useContext } from 'react';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import { setPrevEditorState } from 'redux/editor/editorReducer';
import { activeImageObjectSelector } from 'redux/gallery';
import { prevEditorStateSelector } from 'redux/editor';
import { IPrevEditorState } from 'redux/editor/types';
import {
  getProductAsync,
  productCategoryTypeSelector,
  productQuantitySelector,
  selectedProductOptionsSelector,
} from 'redux/sidePanel';

import scaleFromCenterPoint from 'utils/editor/scale/scaleFromCenterPoint';
import { CropUpdateContext } from 'context/contexts/editor/canvas';
import { Void } from 'types/general';

import usePrevValues from './intersections/usePrevValues';
import useResetEditor from './useResetEditor';

type ReturnValue = [Void, Void];

const useEditorState = (): ReturnValue => {
  const { mainLayerRef, croppedImageRef, imageWrapperRef } =
    useEditorRefs();
  const cropUpdate = useContext(CropUpdateContext);

  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const productQuantity = useAppSelector(productQuantitySelector);
  const prevEditorState = useAppSelector(prevEditorStateSelector);
  const currentProductCategoryType = useAppSelector(
    productCategoryTypeSelector,
  );

  const selectedProductOptions = useAppSelector(
    selectedProductOptionsSelector,
  );

  const dispatch = useAppDispatch();

  const resetEditor = useResetEditor();
  const { setPrevValues } = usePrevValues();

  const saveEditorState = useCallback(() => {
    const imageWrapper = imageWrapperRef.current;
    const croppedImage = croppedImageRef.current;
    const mainLayer = mainLayerRef.current;

    if (
      !imageWrapper ||
      !croppedImage ||
      !mainLayer ||
      !currentProductCategoryType ||
      !activeImageObject
    )
      return;

    const imageWrapperScale = imageWrapper.scale();
    const mainLayerScale = mainLayer.scale();

    if (!imageWrapperScale || !mainLayerScale) return;

    const imageWrapperValues = {
      size: imageWrapper.size(),
      scale: imageWrapperScale,
      rotation: imageWrapper.rotation(),
      position: imageWrapper.getAbsolutePosition(),
    };

    const croppedImageValues = {
      size: croppedImage.size(),
      position: croppedImage.getAbsolutePosition(),
    };

    const mainLayerValues = {
      scale: mainLayerScale,
    };

    const editorState = {
      imageGuid: activeImageObject.imageGuid,
      quantity: productQuantity,
      productCategoryType: currentProductCategoryType,
      selectedOptions: selectedProductOptions,
      imageWrapperValues,
      croppedImageValues,
      mainLayerValues,
      rotation: imageWrapper.rotation(),
    };

    dispatch(setPrevEditorState(editorState));
  }, [
    activeImageObject,
    croppedImageRef,
    currentProductCategoryType,
    dispatch,
    imageWrapperRef,
    mainLayerRef,
    productQuantity,
    selectedProductOptions,
  ]);

  const undoEditorChanges = useCallback(() => {
    const imageWrapper = imageWrapperRef.current;
    const croppedImage = croppedImageRef.current;
    const mainLayer = mainLayerRef.current;

    if (
      !prevEditorState ||
      !activeImageObject ||
      !imageWrapper ||
      !croppedImage ||
      !mainLayer
    )
      return;

    const {
      quantity,
      imageGuid,
      selectedOptions,
      productCategoryType,
      imageWrapperValues,
      croppedImageValues,
      mainLayerValues,
    }: IPrevEditorState = prevEditorState;

    const isEqualImageGuides =
      activeImageObject.imageGuid === imageGuid;

    if (!isEqualImageGuides) return;

    const requestBodyParam = {
      quantity,
      selectedOptions,
      productCategoryType,
    };

    // get prev side panel with selected options
    dispatch(getProductAsync({ requestBodyParam }));

    // reset current editor view
    resetEditor();

    // set prev main layer scale
    const mainLayerScaleValue =
      mainLayer.scaleX() - mainLayerValues.scale.x;

    scaleFromCenterPoint({
      scaleObject: mainLayer,
      scaleX: mainLayerScaleValue,
      isZoomOut: true,
    });

    // set prev croppedImage values
    croppedImage.size(croppedImageValues.size);
    croppedImage.setAbsolutePosition(croppedImageValues.position);

    // set prev imageWrapper values
    const imageWrapperScaleValue =
      imageWrapper.scaleX() - imageWrapperValues.scale.x;

    scaleFromCenterPoint({
      scaleObject: imageWrapper,
      scaleX: imageWrapperScaleValue,
      isZoomOut: true,
    });

    imageWrapper.rotation(imageWrapperValues.rotation);
    imageWrapper.setAbsolutePosition(imageWrapperValues.position);

    cropUpdate();
    setPrevValues();
  }, [
    activeImageObject,
    cropUpdate,
    croppedImageRef,
    dispatch,
    imageWrapperRef,
    mainLayerRef,
    prevEditorState,
    resetEditor,
    setPrevValues,
  ]);

  return [saveEditorState, undoEditorChanges];
};

export default useEditorState;
