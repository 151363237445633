import { useEffect, useState } from 'react';
import { prominent } from 'color.js';

import { Output } from 'types/editor/colors';

interface IConfig {
  amount: number;
  format: string;
  group?: number;
  sample?: number;
}

const useImageColorPresets = (
  imageUrl: string,
  config: IConfig,
): Output => {
  const [nativeColors, setNativeColors] = useState<Output>([]);
  useEffect(() => {
    prominent(imageUrl, config).then((colorsParam: Output) => {
      setNativeColors(colorsParam);
    });
  }, [imageUrl, config]);

  return nativeColors;
};

export default useImageColorPresets;
