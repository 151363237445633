import { FC, useRef } from 'react';

import { IContextProps } from 'types/contextProps';
import { RectType } from 'types/editor/konvaTypes';

import { ColorWrapsRefContext } from 'context/contexts/editor/canvas';

const ColorWrapsContextProvider: FC<IContextProps> = ({
  children,
}) => {
  const topRectRef = useRef<RectType>(null);
  const rightRectRef = useRef<RectType>(null);
  const bottomRectRef = useRef<RectType>(null);
  const leftRectRef = useRef<RectType>(null);

  const colorWrapsRefs = [
    topRectRef,
    rightRectRef,
    bottomRectRef,
    leftRectRef,
  ];

  return (
    <ColorWrapsRefContext.Provider value={colorWrapsRefs}>
      {children}
    </ColorWrapsRefContext.Provider>
  );
};

export default ColorWrapsContextProvider;
