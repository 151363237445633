import cn from 'classnames';
import {
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import useResetEditor from 'hooks/editor/useResetEditor';
import useEditMode from 'hooks/editor/useEditMode';

import scaleFromCenterPoint from 'utils/editor/scale/scaleFromCenterPoint';
import getImageUrlBySize from 'utils/gallery/getImageUrlBySize';

import { getProductAsync, setShowOptions } from 'redux/sidePanel';
import { lineItemsSelector } from 'redux/cart';
import {
  setIsResetCroppedElement,
  setIsResetCustomCorners,
} from 'redux/editor/editorReducer';
import {
  removeActiveImageObject,
  setActiveImageObject,
  setIsShowGallery,
  activeImageObjectSelector,
  deleteImageObjectAsync,
  deleteImageObjectsAsync,
  imageObjectsSelector,
  isGalleryPendingSelector,
} from 'redux/gallery';

import IMAGE_SIZE_INDEXES from 'constants/gallery/imageSizeIndexes';
import { ZOOM_VALUES } from 'constants/editor/zoom';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useAlertModal from 'hooks/context/general/useAlertModal';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import { ConfirmPopupContext } from 'context/contexts/editor/general';
import { Event, Void } from 'types/general';
import {
  alertMessage,
  confirmMessage,
} from 'constants/gallery/messages';

import GalleryFooterMobile from './GalleryFooterMobile';
import GalleryHeader from './GalleryHeader';
import GalleryCard from './GalleryCard';
import GalleryBody from './GalleryBody';

interface IProps {
  hidePopupsHandler: (e: Event<HTMLElement>) => void;
}

interface DefaultCounts {
  [key: string]: number;
}

const Gallery: FC<IProps> = ({ hidePopupsHandler }) => {
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [isOpen, setOpen] = useState(false);

  const setConfirmPopupStateFunc = useContext(ConfirmPopupContext);
  const handleShowModal = useAlertModal();
  const { mainLayerRef } = useEditorRefs();

  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const isGalleryPending = useAppSelector(isGalleryPendingSelector);
  const imageObjects = useAppSelector(imageObjectsSelector);
  const lineItems = useAppSelector(lineItemsSelector);

  const dispatch = useAppDispatch();

  const { isMobile } = useDefineDeviceByWindowSize();
  const [isEditMode, setIsEditMode] = useEditMode();
  const resetEditor = useResetEditor();

  const resetEditorPage = useCallback(
    (callback?: Void) => {
      if (isEditMode) {
        resetEditor();
        setIsEditMode(false);
      }

      dispatch(setIsResetCroppedElement(true));
      dispatch(setIsResetCustomCorners(true));
      dispatch(setShowOptions(true));
      dispatch(removeActiveImageObject());
      dispatch(getProductAsync({}));

      if (callback) {
        callback();
      }
    },
    [dispatch, isEditMode, resetEditor, setIsEditMode],
  );

  const slideGallery = useCallback(() => setOpen(!isOpen), [isOpen]);

  const selectAllImagesHandler = useCallback(() => {
    if (!imageObjects.length) return;

    const imagesGuides = imageObjects.map(
      ({ imageGuid }) => imageGuid,
    );

    setSelectedImages(imagesGuides);
  }, [imageObjects]);

  const selectImageByCornerClick = useCallback(
    (imageGuid: string) => {
      if (!selectedImages.includes(imageGuid)) {
        return setSelectedImages([...selectedImages, imageGuid]);
      }

      const updatedSelectedImages = [
        ...selectedImages.filter(
          (imageGuidParam) => imageGuidParam !== imageGuid,
        ),
      ];

      return setSelectedImages(updatedSelectedImages);
    },
    [selectedImages],
  );

  const checkImageInCart = useCallback(() => {
    if (selectedImages.length) {
      const isInCart = selectedImages.every((selectedImage) => {
        return lineItems.some((lineItem) => {
          const imageGuid = lineItem?.sides?.[0].imageGuid;

          if (!imageGuid) return false;

          return selectedImage === imageGuid;
        });
      });

      if (!isInCart) return false;

      handleShowModal(alertMessage);

      return true;
    }

    const isInCart = lineItems.some((lineItem) => {
      const imageGuid = lineItem?.sides?.[0].imageGuid;

      if (!imageGuid) return false;

      return imageGuid === activeImageObject?.imageGuid;
    });

    if (!isInCart) return false;

    handleShowModal(alertMessage);

    return true;
  }, [activeImageObject, lineItems, selectedImages, handleShowModal]);

  const handleDeleteImages = useCallback(() => {
    const isInCart = checkImageInCart();

    if (isInCart) return null;

    if (!selectedImages.length) {
      if (!activeImageObject) return null;

      const confirmHandler = () =>
        resetEditorPage(() =>
          dispatch(
            deleteImageObjectAsync(activeImageObject.imageGuid),
          ),
        );

      return setConfirmPopupStateFunc({
        text: confirmMessage,
        confirmHandler,
      });
    }

    const isEqualLength =
      imageObjects.length === selectedImages.length;

    if (isEqualLength) {
      if (!activeImageObject) {
        setSelectedImages([]);

        return dispatch(deleteImageObjectsAsync());
      }

      const confirmHandler = () => {
        setSelectedImages([]);

        resetEditorPage(() => dispatch(deleteImageObjectsAsync()));
      };

      return setConfirmPopupStateFunc({
        text: confirmMessage,
        confirmHandler,
      });
    }

    const isActiveImageSelected = selectedImages.find(
      (imageGuid) => imageGuid === activeImageObject?.imageGuid,
    );

    if (isActiveImageSelected) {
      const confirmHandler = () => {
        setSelectedImages([]);

        resetEditorPage(() =>
          selectedImages.forEach((imageGuid) => {
            dispatch(deleteImageObjectAsync(imageGuid));
          }),
        );
      };

      return setConfirmPopupStateFunc({
        text: confirmMessage,
        confirmHandler,
      });
    }

    setSelectedImages([]);

    return selectedImages.forEach((imageGuid) =>
      dispatch(deleteImageObjectAsync(imageGuid)),
    );
  }, [
    activeImageObject,
    checkImageInCart,
    dispatch,
    imageObjects.length,
    resetEditorPage,
    selectedImages,
    setConfirmPopupStateFunc,
  ]);

  const handleSelectImage = useCallback(
    (e: Event<HTMLDivElement>, imageGuidParam: string) => {
      const target = e.target as HTMLDivElement;

      if (isGalleryPending) return null;

      if (target.id === 'gallery-card-container') {
        return selectImageByCornerClick(imageGuidParam);
      }

      const imageObject = imageObjects.find(
        ({ imageGuid }) => imageGuidParam === imageGuid,
      );

      if (!imageObject) return null;

      resetEditorPage();

      if (!isEditMode && activeImageObject) {
        const layer = mainLayerRef.current;

        if (!layer) return null;

        scaleFromCenterPoint({
          scaleObject: layer,
          isZoomOut: true,
          scaleX: layer.scaleX() - ZOOM_VALUES.START_ZOOM,
        });
      }

      if (isMobile) {
        dispatch(setIsShowGallery(false));
      }

      return dispatch(setActiveImageObject(imageObject));
    },
    [
      activeImageObject,
      dispatch,
      imageObjects,
      isEditMode,
      isGalleryPending,
      isMobile,
      mainLayerRef,
      resetEditorPage,
      selectImageByCornerClick,
    ],
  );

  const imageCountsInCart = useMemo(() => {
    const defaultCounts: DefaultCounts = {};

    return lineItems.reduce((acc, lineItem): DefaultCounts => {
      const { sides } = lineItem;

      if (!sides) return acc;

      const [side] = sides;
      const { imageGuid } = side;

      return {
        ...acc,
        [imageGuid]: acc[imageGuid] + 1 || 1,
      };
    }, defaultCounts);
  }, [lineItems]);

  const GalleryCardList = imageObjects.map((imageObject) => (
    <GalleryCard
      key={imageObject.imageGuid}
      selectedImages={selectedImages}
      imageGuid={imageObject.imageGuid}
      handleSelectImage={handleSelectImage}
      originalFileName={imageObject.originalFileName}
      countInCart={imageCountsInCart[imageObject.imageGuid] || 0}
      isSelected={
        imageObject.imageGuid === activeImageObject?.imageGuid
      }
      imageUrl={getImageUrlBySize(
        imageObject,
        IMAGE_SIZE_INDEXES.SMALL,
      )}
    />
  ));

  return (
    <div
      id="gallery"
      className={cn('gallery', { 'is-opened': isOpen })}
    >
      <GalleryHeader
        handleClick={slideGallery}
        imageCounts={imageObjects.length}
        handleDeleteImages={handleDeleteImages}
        selectedImageCounts={selectedImages.length}
        handleSelectAllImages={selectAllImagesHandler}
      />
      <GalleryBody
        isOpen={isOpen}
        resetEditorPage={resetEditorPage}
        hidePopupsHandler={hidePopupsHandler}
      >
        {GalleryCardList}
      </GalleryBody>
      <GalleryFooterMobile
        handleDeleteImages={handleDeleteImages}
        handleSelectAllImages={selectAllImagesHandler}
      />
    </div>
  );
};

export default Gallery;
