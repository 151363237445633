import { useCallback, useContext, useState } from 'react';

import getActualShapeSize from 'utils/editor/intersectionChecks/getActualShapeSize';
import scaleFromCenterPoint from 'utils/editor/scale/scaleFromCenterPoint';
import rotateService from 'utils/editor/rotate/RotateService';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import { visualRotationSelector } from 'redux/editor';
import {
  setCroppedImageDpi,
  setVisualRotation,
} from 'redux/editor/editorReducer';

import { CropUpdateContext } from 'context/contexts/editor/canvas';
import { ZOOM_VALUES } from 'constants/editor/zoom';

import useShapeIntersectionChecks from '../intersections/useShapeIntersectionChecks';
import usePrevValues from '../intersections/usePrevValues';
import useCroppedImageDpi from '../view/useCroppedImageDpi';

type Result = [
  rotation: number,
  setRotation: (nextDegrees: number) => void,
];

const useRotation = (): Result => {
  const cropUpdate = useContext(CropUpdateContext);
  const { mainLayerRef, imageWrapperRef, croppedImageRef } =
    useEditorRefs();

  const visualRotation = useAppSelector(visualRotationSelector);

  const dispatch = useAppDispatch();

  const applyShapeIntersection = useShapeIntersectionChecks();
  const { setPrevValues } = usePrevValues();
  const [, calcDpi] = useCroppedImageDpi();

  const setRotation = useCallback(
    (rotation: number) => {
      const mainLayer = mainLayerRef.current;
      const imageWrapper = imageWrapperRef.current;
      const croppedImage = croppedImageRef.current;

      if (!mainLayer || !imageWrapper || !croppedImage) return;

      const imgWrapAbsPos = imageWrapper.getAbsolutePosition();
      const croppedImageAbsPos = croppedImage.getAbsolutePosition();

      const mainLayerScale = {
        x: mainLayer.scaleX(),
        y: mainLayer.scaleY(),
      };

      const croppedImageScale = {
        x: croppedImage.scaleX(),
        y: croppedImage.scaleY(),
      };

      const actualCropSize = getActualShapeSize(
        croppedImage.size(),
        croppedImageScale,
        mainLayerScale,
      );

      const croppedImageCenter = {
        x: +(actualCropSize.width / 2 + croppedImageAbsPos.x).toFixed(
          3,
        ),
        y: +(
          actualCropSize.height / 2 +
          croppedImageAbsPos.y
        ).toFixed(3),
      };

      const rad = rotateService.getRadians(rotation);

      const x =
        croppedImageCenter.x +
        (imgWrapAbsPos.x - croppedImageCenter.x) * Math.cos(rad) -
        (imgWrapAbsPos.y - croppedImageCenter.y) * Math.sin(rad);

      const y =
        croppedImageCenter.y +
        (imgWrapAbsPos.x - croppedImageCenter.x) * Math.sin(rad) +
        (imgWrapAbsPos.y - croppedImageCenter.y) * Math.cos(rad);

      // move the rotated shape in relation to the rotation point.
      imageWrapper.setAbsolutePosition({ x, y });

      const validatedByDegrees = rotateService.rotationValidation(
        imageWrapper.rotation() + rotation,
      );

      // rotate the shape in place around its natural rotation point
      imageWrapper.rotation(validatedByDegrees);

      while (true) {
        const shapeIntersection = applyShapeIntersection();

        if (!shapeIntersection) break;

        scaleFromCenterPoint({
          scaleObject: imageWrapper,
          isZoomOut: false,
          scaleX: ZOOM_VALUES.ZOOM_DURING_SHAPE_INTERSECTION,
        });
      }

      cropUpdate();
      setPrevValues();

      const dpi = calcDpi();

      dispatch(setCroppedImageDpi(dpi));

      dispatch(
        setVisualRotation(Math.round(imageWrapper.rotation())),
      );
    },
    [
      applyShapeIntersection,
      calcDpi,
      croppedImageRef,
      cropUpdate,
      dispatch,
      imageWrapperRef,
      mainLayerRef,
      setPrevValues,
    ],
  );

  return [visualRotation, setRotation];
};

export default useRotation;
