import { FileWithPath } from 'react-dropzone';
import { Dispatch } from '@reduxjs/toolkit';

import getImageLoopAsync from 'utils/gallery/getImageLoopAsync';
import galleryService from 'api/GalleryService';
import { GetState } from 'redux/types';

import {
  addImageObject,
  deleteImageObject,
  deleteImageObjects,
  setActiveImageObject,
  setImageObjects,
  setIsGalleryPending,
} from './galleryReducer';

export const uploadImageAsync =
  (
    file: FileWithPath,
    isActive = false,
    handleShowModal?: (errorMessage: string) => void,
  ) =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { sessionId } = getState().auth.session;

      if (!sessionId) return;

      dispatch(setIsGalleryPending(true));

      const stopGalleryPending = () =>
        dispatch(setIsGalleryPending(false));

      const imageStorage = await galleryService.getNewStorageUrl(
        sessionId,
        file.name,
      );

      if (!imageStorage) return;

      await galleryService.uploadFileToUrl(imageStorage.url, file);

      const imageObject = await getImageLoopAsync(
        sessionId,
        imageStorage.imageGuid,
        handleShowModal,
      );

      if (Object.values(imageObject).length) {
        dispatch(addImageObject(imageObject));

        if (isActive) {
          dispatch(setActiveImageObject(imageObject));
          stopGalleryPending();
        }
      } else if (isActive) {
        stopGalleryPending();
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getImageObjectsAsync =
  (sessionId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const images = await galleryService.getImages(sessionId);

    if (!images) return;

    dispatch(setImageObjects(images));
  };

export const deleteImageObjectsAsync =
  () =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { sessionId } = getState().auth.session;

      if (!sessionId) return;

      await galleryService.deleteImages(sessionId);

      dispatch(deleteImageObjects());
    } catch (error) {
      console.log(error);
    }
  };

export const deleteImageObjectAsync =
  (imageGuid: string) =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { sessionId } = getState().auth.session;

    if (!sessionId) return;

    await galleryService.deleteImage(sessionId, imageGuid);

    dispatch(deleteImageObject(imageGuid));
  };
