import { SIDE_PANEL_ROUTES } from 'constants/routes/app/routes';

import { productCategories } from './productCategories';

const [acrylicPrint, metalPrint, canvasWrap, acrylicBlock] =
  productCategories;
const { METAL_PRINT, ACRYLIC_PRINT, CANVAS_WRAP, ACRYLIC_BLOCK } =
  SIDE_PANEL_ROUTES;

const sidePanelRoutesDependOnCategory = {
  [metalPrint.productCategoryType]: METAL_PRINT,
  [acrylicPrint.productCategoryType]: ACRYLIC_PRINT,
  [canvasWrap.productCategoryType]: CANVAS_WRAP,
  [acrylicBlock.productCategoryType]: ACRYLIC_BLOCK,
};

export default sidePanelRoutesDependOnCategory;
