import { createSelector } from 'reselect';

import { State } from 'redux/types';

const editorMobileStateSelector = (state: State) => state.editorMobile;

export const editorMobileCropObjectSelector = createSelector(
  editorMobileStateSelector,
  ({ cropObject }) => cropObject,
);

export const editorMobileRotationSelector = createSelector(
  editorMobileStateSelector,
  ({ rotation }) => rotation,
);

export const editorMobileImageScaleSelector = createSelector(
  editorMobileStateSelector,
  ({ imageScale }) => imageScale,
);
