/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IColorPickerState } from './types';

const initialState: IColorPickerState = {
  showColorPicker: false,
  eyeDropperMode: false,
  color: '',
  hideMobileColorPicker: false
};

const colorPickerSlice = createSlice({
  name: 'colorPicker',
  initialState,
  reducers: {
    setShowColorPicker: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.showColorPicker = payload;
    },
    setEyeDropperMode: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.eyeDropperMode = payload;
    },
    setColor: (state, { payload }: PayloadAction<string>) => {
      state.color = payload;
    },
    setHideColorPickerMobile: (state, { payload }: PayloadAction<boolean>) => {
      state.hideMobileColorPicker = payload;
    },
  },
});

export const { setShowColorPicker, setEyeDropperMode, setColor, setHideColorPickerMobile } =
  colorPickerSlice.actions;

export default colorPickerSlice.reducer;
