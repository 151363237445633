/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {ICoordinates, ICropObject} from "types/editor";
import { IEditorMobileState } from './types';

const initialState: IEditorMobileState = {
  cropObject: {
    pixelWidth: 0,
    pixelHeight: 0,
    cropXPrecent: 0,
    cropYPrecent: 0,
    cropWidthPrecent: 0,
    cropHeightPrecent: 0
  },
  rotation: 0,
  imageScale: null
};

const editorMobileSlice = createSlice({
  name: 'editorMobile',
  initialState,
  reducers: {
    setEditorMobileCropObject: (
      state,
      { payload }: PayloadAction<ICropObject>,
    ) => {
      state.cropObject = payload;
    },
    setEditorMobileRotation: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.rotation = payload;
    },
    setEditorMobileImageScale: (
      state,
      { payload } : PayloadAction<ICoordinates>,
    ) => {
      state.imageScale = payload;
    },
  },
});

export const {
  setEditorMobileCropObject,
  setEditorMobileRotation,
  setEditorMobileImageScale
} = editorMobileSlice.actions;

export default editorMobileSlice.reducer;
