export const STAGE_PADDING = 40;
export const DPI_LIMIT = 150;
export const RECOMMENDED_DPI = 200;

export const MIN_SLIDER_VALUE = -30;
export const MAX_SLIDER_VALUE = 30;
export const SLIDER_STEP = 1;

export const lessDpiMessage =
  'Selected image and crop is too small for the selected product size. Must be at least 150 dpi. Please select a smaller size product or a larger image area';

export const editorConstants = {
  isSaveButtonClicked: false,
  newRotationDegreePositive: 0,
  scaleValue: 0,
  prevDegrees: 0,
};
