import { FC } from 'react';

import { Void } from 'types/general';
import Button from 'components/Buttons/Button';

interface IProps {
  handleSelectAllImages: Void;
  handleDeleteImages: Void;
}

const GalleryFooterMobile: FC<IProps> = ({
  handleDeleteImages,
  handleSelectAllImages,
}) => {
  return (
    <div className="gallery-footer">
      <Button
        className="btn-secondary gallery-footer-btn"
        clickHandler={handleSelectAllImages}
      >
        select all
      </Button>
      <Button
        className="btn-secondary gallery-footer-btn"
        clickHandler={handleDeleteImages}
      >
        delete
      </Button>
    </div>
  );
};

export default GalleryFooterMobile;
