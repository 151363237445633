import { FC } from 'react';

interface IProps {
  alt: string;
  fileName: string;
  imageUrl: string;
}

const CartImage: FC<IProps> = ({ alt, fileName, imageUrl }) => (
  <div className="cart-image-container">
    <div className="cart-img-container">
      <img
        src={`data:image/[format];base64,${imageUrl}`}
        alt={alt}
        className="cart-img"
      />
    </div>
    <span className="cart-file-name">{fileName}</span>
  </div>
);

export default CartImage;
