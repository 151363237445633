import { useCallback, useContext } from 'react';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppSelector from 'hooks/redux/useAppSelector';

import {
  isEditLineItemModeSelector,
  isSetupEditorViewSelector,
} from 'redux/editor';
import {
  edgeSizePixelsSelector,
  selectedEdgeSelector,
} from 'redux/sidePanel';

import { RectRefType } from 'types/editor/refTypes';
import { Void } from 'types/general';

import { ColorWrapsRefContext } from 'context/contexts/editor/canvas';
import {
  colorWrapsId,
  edgeOptionsId,
} from 'constants/editor/canvasWraps';

interface IColorWrapRef {
  rectRef: RectRefType;
  id: string;
}

interface Result {
  colorWrapsRefs: IColorWrapRef[];
  setupColorWraps: Void;
}

const useSetupColorWraps = (): Result => {
  const colorWrapRefs = useContext(ColorWrapsRefContext);

  const selectedEdgeOption = useAppSelector(selectedEdgeSelector);
  const edgeSizePixels = useAppSelector(edgeSizePixelsSelector);
  const isEditLineItemMode = useAppSelector(
    isEditLineItemModeSelector,
  );
  const isSetupEditorView = useAppSelector(isSetupEditorViewSelector);

  const { croppedImageRef, mainLayerRef } = useEditorRefs();

  const colorWrapsRefs = colorWrapRefs.map((ref, index) => ({
    rectRef: ref,
    id: colorWrapsId[index],
  }));

  const setupColorWraps = useCallback(() => {
    const [topRectRef, rightRectRef, bottomRectRef, leftRectRef] =
      colorWrapRefs;
    const croppedImage = croppedImageRef.current;
    const mainLayer = mainLayerRef.current;
    const topRect = topRectRef.current;
    const rightRect = rightRectRef.current;
    const bottomRect = bottomRectRef.current;
    const leftRect = leftRectRef.current;

    if (
      !topRect ||
      !croppedImage ||
      !rightRect ||
      !mainLayer ||
      !bottomRect ||
      !leftRect
    )
      return;

    if (isEditLineItemMode) {
      if (!isSetupEditorView) return;
    }

    const layerScale = mainLayer.scaleX();

    const [, COLOR] = edgeOptionsId;

    const isColorOption =
      COLOR === selectedEdgeOption?.productOptionId;

    if (!isColorOption) return;

    const updatedEdgeSize = edgeSizePixels * layerScale;

    const size = croppedImage.size();
    const pos = croppedImage.getAbsolutePosition();

    const absSize = {
      width: size.width * layerScale,
      height: size.height * layerScale,
    };

    const horizontalSize = {
      width: size.width,
      height: edgeSizePixels,
    };
    const verticalSize = {
      width: edgeSizePixels,
      height: size.height,
    };

    topRect.size(horizontalSize);
    rightRect.size(verticalSize);
    bottomRect.size(horizontalSize);
    leftRect.size(verticalSize);

    const topRectPos = {
      x: pos.x,
      y: pos.y - updatedEdgeSize,
    };

    const rightRectPos = {
      x: pos.x + absSize.width,
      y: pos.y,
    };

    const bottomRectPos = {
      x: pos.x,
      y: pos.y + absSize.height,
    };

    const leftRectPos = {
      x: pos.x - updatedEdgeSize,
      y: pos.y,
    };

    topRect.setAbsolutePosition(topRectPos);
    rightRect.setAbsolutePosition(rightRectPos);
    bottomRect.setAbsolutePosition(bottomRectPos);
    leftRect.setAbsolutePosition(leftRectPos);
  }, [
    colorWrapRefs,
    isEditLineItemMode,
    isSetupEditorView,
    mainLayerRef,
    edgeSizePixels,
    selectedEdgeOption,
    croppedImageRef,
  ]);

  setupColorWraps();

  return { colorWrapsRefs, setupColorWraps };
};

export default useSetupColorWraps;
