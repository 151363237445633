/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ISidePanelState,
  IProduct,
  IProductCategoryStatus,
} from './types';

const initialState: ISidePanelState = {
  showOptions: true,
  isSidePanelPending: false,
  isSidePanelFulfilled: false,
  product: {
    quantity: 0,
    productId: null,
    optionGroups: [],
    productCategory: null,
    productValidation: null,
    canAddProductToCart: false,
    pricingAsConfigured: {
      total: 0,
      minPrice: 0,
      maxPrice: 0,
      formattedPrice: '0.00',
    },
    quantityRules: {
      min: 1,
      max: 9999,
      step: 1,
    },
    workspace: [
      {
        workspaceImages: [
          {
            url: '',
            size: '',
            color: '',
          },
        ],
      },
    ],
  },
  productCategoriesStatus: [],
};

const sidePanelSlice = createSlice({
  name: 'sidePanel',
  initialState,
  reducers: {
    setProduct: (state, { payload }: PayloadAction<IProduct>) => {
      state.product = payload;
    },
    setShowOptions: (state, { payload }: PayloadAction<boolean>) => {
      state.showOptions = payload;
    },
    setIsSidePanelPending: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isSidePanelPending = payload;
    },
    setIsSidePanelFulfilled: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isSidePanelFulfilled = payload;
    },
    setProductCatagoriesStatus: (
      state,
      { payload }: PayloadAction<IProductCategoryStatus[]>,
    ) => {
      state.productCategoriesStatus = payload;
    },
  },
});

export const {
  setProduct,
  setShowOptions,
  setIsSidePanelPending,
  setIsSidePanelFulfilled,
  setProductCatagoriesStatus,
} = sidePanelSlice.actions;

export default sidePanelSlice.reducer;
