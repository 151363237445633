export const productCategories = [
  {
    productCategoryId: 0,
    productCategoryType: 'AcrylicPrint',
    displayName: 'Acrylic Print',
  },
  {
    productCategoryId: 1,
    productCategoryType: 'MetalPrint',
    displayName: 'Metal Print',
  },
  {
    productCategoryId: 2,
    productCategoryType: 'CanvasWrap',
    displayName: 'Canvas Wrap',
  },
  {
    productCategoryId: 3,
    productCategoryType: 'AcrylicBlock',
    displayName: 'Acrylic Blocks',
  },
];

export const defaultProductCategoryType =
  productCategories[1].productCategoryType;

export const defaultGetProductBody = {
  quantity: 0,
  selectedOptions: [],
};
