import { useCallback } from 'react';
import {
  DropzoneInputProps,
  DropzoneRootProps,
  FileWithPath,
  useDropzone,
} from 'react-dropzone';

import { IMAGE_SIZE_UPLOAD_LIMIT } from 'constants/gallery/general';
import { setIsShowGallery, uploadImageAsync } from 'redux/gallery';

import useDefineDeviceByWindowSize from './useDefineDeviceByWindowSize';
import useAlertModal from './context/general/useAlertModal';
import useAppDispatch from './redux/useAppDispatch';

type Result = [
  getRootProps: DropzoneRootProps,
  getInputProps: DropzoneInputProps,
  isDragActive: boolean,
];

const useUpload = (): Result => {
  const handleShowModal = useAlertModal();

  const dispatch = useAppDispatch();

  const { isMobile } = useDefineDeviceByWindowSize();

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      acceptedFiles.forEach((file, index) => {
        const sizeInMb = file.size / 1024 / 1024;
        if (file.type !== 'image/jpeg') {
          return handleShowModal(
            'Your picture should have JPEG extension',
          );
        }
        if (sizeInMb > IMAGE_SIZE_UPLOAD_LIMIT) {
          return handleShowModal(
            'Your image upload size more than available limit',
          );
        }

        if (!index) {
          return dispatch(
            uploadImageAsync(file, true, handleShowModal),
          );
        }

        return dispatch(
          uploadImageAsync(file, false, handleShowModal),
        );
      });

      if (!isMobile) {
        dispatch(setIsShowGallery(true));
      }
    },
    [isMobile, dispatch, handleShowModal],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg',
    onDrop,
    onDropRejected: (files) =>
      handleShowModal(
        "Please note: Only JPG/JPEG files are allowed for upload. Ensure your file has the '.jpg' extension before proceeding.",
      ),
  });

  return [getRootProps(), getInputProps(), isDragActive];
};

export default useUpload;
