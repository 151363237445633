import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import useSelectProductOptions from 'hooks/sidePanel/useSelectProductOptions';
import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useSidebarSettings from 'hooks/sidePanel/useSidebarSettings';
import useProductSize from 'hooks/sidePanel/useProductSize';
import useResetEditor from 'hooks/editor/useResetEditor';
import useAppSelector from 'hooks/redux/useAppSelector';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAnimation from 'hooks/sidePanel/useAnimate';
import useEditMode from 'hooks/editor/useEditMode';

import {
  filtersUsedSelector,
  isSelectedOrientationSelector,
  productSizeOptionsSelector,
  selectedProductSizeOptionSelector,
  setShowOptions,
  showOptionsSelector,
} from 'redux/sidePanel';
import {
  activeImageObjectSelector,
  setIsShowGallery,
} from 'redux/gallery';

import { EditorWindowContext } from 'context/contexts/editor/general';
import { IProductOption, ISizeString } from 'types/general';
import { SIZE } from 'constants/sidePanel/general';

import SizeFilters from 'types/sidePanel/sizeFilters';
import SidebarControls from '../SidebarControls';
import SidebarSettings from '../SidebarSettings';
import Orientation from '../../../Orientation';
import SizeCard from './SizeCard';

const SizeCards: FC = () => {
  const [sizeFilter, setSizeFilter] = useState<SizeFilters>(
    SizeFilters.IS_STANDARD,
  );
  const [animations, isClicked, setIsClicked] = useAnimation();
  const [productCardId, setProductCardId] = useState<null | string>(
    null,
  );

  const { showEditorWindowHandler } = useContext(EditorWindowContext);

  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const filtersUsed = useAppSelector(filtersUsedSelector);
  const showOptions = useAppSelector(showOptionsSelector);
  const selectedProductSize = useAppSelector(
    selectedProductSizeOptionSelector,
  );
  const isSelectedOrientation = useAppSelector(
    isSelectedOrientationSelector,
  );
  const productSizeOptions = useAppSelector(
    productSizeOptionsSelector,
  );

  const dispatch = useAppDispatch();

  const selectProductOption = useSelectProductOptions(sizeFilter);
  const { isMobile } = useDefineDeviceByWindowSize();
  const [isEditMode, setIsEditMode] = useEditMode();
  const applyProductSize = useProductSize();
  const resetEditor = useResetEditor();

  const [showSidebarSettings, showSidebarSettingsHandler] =
    useSidebarSettings();

  const isDisabled = !activeImageObject || !isSelectedOrientation;

  const selectProductSize = useCallback(
    (size: ISizeString) => {
      if (isMobile) return;

      if (!isEditMode) {
        setIsEditMode(true);
      }

      resetEditor();

      applyProductSize(size);
    },
    [
      applyProductSize,
      isEditMode,
      isMobile,
      resetEditor,
      setIsEditMode,
    ],
  );

  const selectProductOptionHandler = useCallback(
    (productOption: IProductOption) => {
      if (isDisabled) return null;

      const { size } = productOption;

      const currentSize = selectedProductSize?.size;

      if (!size) return null;

      const isEqualSize =
        currentSize?.width === size.width &&
        currentSize?.height === size.height;

      showSidebarSettingsHandler(false);

      if (isEqualSize) return null;

      selectProductSize(size);

      selectProductOption(productOption);

      if (showOptions) dispatch(setShowOptions(false));

      if (isMobile) return showEditorWindowHandler(true);

      return dispatch(setIsShowGallery(false));
    },
    [
      dispatch,
      isMobile,
      isDisabled,
      showOptions,
      selectProductSize,
      selectedProductSize,
      selectProductOption,
      showEditorWindowHandler,
      showSidebarSettingsHandler,
    ],
  );

  const changeFilterHandler = useCallback(
    (sizeFilterStr: SizeFilters) => setSizeFilter(sizeFilterStr),
    [],
  );

  const filteredProductSizeOptions = productSizeOptions.filter(
    (productSizeOption) =>
      productSizeOption.filters.includes(sizeFilter),
  );

  const filteredOptionList = filteredProductSizeOptions.filter(
    ({ productOptionId }: IProductOption) => {
      if (!productCardId || !isClicked) return true;

      return productCardId === productOptionId;
    },
  );

  const productSizeOptionsList = filteredOptionList.map(
    (productOptions: IProductOption) => (
      <SizeCard
        disable={!activeImageObject}
        animations={animations}
        isClicked={isClicked}
        setIsClicked={setIsClicked}
        setCardId={setProductCardId}
        productOptions={productOptions}
        key={productOptions.productOptionId}
        selectProductOptionHandler={selectProductOptionHandler}
      />
    ),
  );

  useEffect(() => {
    if (!filtersUsed) return;

    setSizeFilter(filtersUsed[0]);
  }, [filtersUsed]);

  return (
    <SidebarSettings
      title={SIZE}
      disable={!activeImageObject}
      showSidebarSettings={showSidebarSettings}
      selectedOption={selectedProductSize}
      showSidebarSettingsHandler={showSidebarSettingsHandler}
    >
      <Orientation />
      <SidebarControls
        disable={!activeImageObject}
        sizeFilter={activeImageObject ? sizeFilter : ''}
        changeFilterHandler={changeFilterHandler}
      />
      <div className="product-size-grid">
        {productSizeOptionsList}
      </div>
    </SidebarSettings>
  );
};

export default SizeCards;
