import { FC, useCallback, useRef } from 'react';

import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import { setIsResetCroppedElement } from 'redux/editor/editorReducer';
import { isResetCroppedElementSelector } from 'redux/editor';

import editorCoreService from 'utils/editor/editorCore/EditorCoreService';
import { IContextProps } from 'types/contextProps';
import {
  CropUpdateContext,
  CropStartContext,
  EditorRefsContext,
} from 'context/contexts/editor/canvas';
import {
  GroupType,
  ImageType,
  LayerType,
  ShapeType,
  StageType,
} from 'types/editor/konvaTypes';
import ColorWrapsContextProvider from './ColorWrapsContextProvider';

const EditorCoreContextProvider: FC<IContextProps> = ({
  children,
}) => {
  const croppedElementRef = useRef<ShapeType>(null);
  const croppedImageRef = useRef<ImageType>(null);
  const imageWrapperRef = useRef<GroupType>(null);
  const mainLayerRef = useRef<LayerType>(null);
  const imageRef = useRef<ImageType>(null);
  const stageRef = useRef<StageType>(null);

  const editorRefs = {
    stageRef,
    imageRef,
    mainLayerRef,
    imageWrapperRef,
    croppedImageRef,
    croppedElementRef,
  };

  const isResetCroppedElement = useAppSelector(
    isResetCroppedElementSelector,
  );

  const dispatch = useAppDispatch();

  const cropStart = useCallback(() => {
    const croppedElement = croppedElementRef.current;
    const croppedImage = croppedImageRef.current;
    const mainLayer = mainLayerRef.current;
    const imageWrapper = imageWrapperRef.current;
    const image = imageRef.current;

    if (
      !croppedImage ||
      !image ||
      !croppedElement ||
      !mainLayer ||
      !imageWrapper
    )
      return;

    if (isResetCroppedElement) {
      croppedElement.setAttrs({
        x: 0,
        y: 0,
        width: croppedImage.width(),
        height: croppedImage.height(),
        rotation: 0,
      });

      dispatch(setIsResetCroppedElement(false));
    }

    const productTransform = croppedImage.getAbsoluteTransform();
    const layerTransform = mainLayer.getAbsoluteTransform();
    const croppedElementTransform =
      croppedElement.getAbsoluteTransform();

    const matrix = layerTransform
      .copy()
      .invert()
      .multiply(productTransform)
      .multiply(croppedElementTransform)
      .getMatrix();

    const imageOptions = editorCoreService.qrDecompose(matrix);

    image.setAttrs({
      width: croppedElement.width(),
      height: croppedElement.height(),
    });

    imageWrapper.setAttrs({
      ...imageOptions,
      width: croppedElement.width(),
      height: croppedElement.height(),
    });
  }, [dispatch, isResetCroppedElement]);

  const cropUpdate = useCallback(() => {
    const croppedElement = croppedElementRef.current;
    const croppedImage = croppedImageRef.current;
    const imageWrapper = imageWrapperRef.current;

    if (!croppedImage || !croppedElement || !imageWrapper) return;

    const croppedImageMatrix = croppedImage
      .getAbsoluteTransform()
      .getMatrix();

    const imageWrapperMatrix = imageWrapper
      .getAbsoluteTransform()
      .getMatrix();

    const croppedElementOptions = editorCoreService.getGroupCoords(
      croppedImageMatrix,
      imageWrapperMatrix,
    );

    croppedElement.setAttrs(croppedElementOptions);
  }, [croppedElementRef, imageWrapperRef, croppedImageRef]);

  return (
    <EditorRefsContext.Provider value={editorRefs}>
      <CropUpdateContext.Provider value={cropUpdate}>
        <CropStartContext.Provider value={cropStart}>
          <ColorWrapsContextProvider>
            {children}
          </ColorWrapsContextProvider>
        </CropStartContext.Provider>
      </CropUpdateContext.Provider>
    </EditorRefsContext.Provider>
  );
};

export default EditorCoreContextProvider;
