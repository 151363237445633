/* eslint-disable no-await-in-loop */
import { AxiosError } from 'axios';
import galleryService from 'api/GalleryService';
import { IImageObject } from 'types/gallery';
import delay from 'utils/general/delay';

const getImageLoopAsync = async (
  sessionId: string,
  imageGuid: string,
  showErrorModal?: (errorMessage: string) => void,
): Promise<IImageObject> => {
  let isDelay = false;
  let isLoop = true;

  while (isLoop) {
    if (isDelay) {
      await delay(0.5);
    }

    const result = await galleryService.getImage(
      sessionId,
      imageGuid,
    );
    const errorResponse = result as AxiosError;
    if (errorResponse?.response?.status === 400) {
      isLoop = false;
      showErrorModal?.(errorResponse.response.data.imageStatusMsg);
      break;
    }
    isDelay = true;
    const imageObject = result as IImageObject;

    if (imageObject?.isProcessed) {
      return imageObject;
    }
  }
  return {} as IImageObject;
};

export default getImageLoopAsync;
