import { useCallback, useContext, FC } from 'react';
import { useHistory } from 'react-router-dom';

import useAppSelector from 'hooks/redux/useAppSelector';
import useAppDispatch from 'hooks/redux/useAppDispatch';

import SelectedOptionsList from 'components/SelectedOptionsList';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icons/Icon';

import CHECKOUT_ROUTES from 'constants/routes/checkout/routes';

import { getProductAsync } from 'redux/sidePanel';
import { lastLineItemSelector } from 'redux/cart';
import {
  removeActiveImageObject,
  setIsShowGallery,
} from 'redux/gallery';

import { SidebarPopupContext } from 'context/contexts/editor/general';
import { Event } from 'types/general';

const SidebarPopup: FC = () => {
  const { showSidebarPopupHandler } = useContext(SidebarPopupContext);

  const lineItem = useAppSelector(lastLineItemSelector);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const imageUrl = lineItem?.lineItemImage;

  const switchToCheckout = useCallback(() => {
    dispatch(getProductAsync({}));
    dispatch(removeActiveImageObject());

    history.push(CHECKOUT_ROUTES.ORDER_INFORMATION);
  }, [dispatch, history]);

  const stopPropagationHandler = (e: Event<HTMLDivElement>) =>
    e.stopPropagation();

  const hideSidebarPopupHandler = () =>
    showSidebarPopupHandler(false);

  const continueOrderingHandler = () => {
    hideSidebarPopupHandler();
    dispatch(setIsShowGallery(true));
  };

  const selectedOptions = lineItem?.selectedOptions || [];
  const total = lineItem?.lineItemTotal || '0.00';

  return (
    <>
      <div className="sidebar-popup" onClick={stopPropagationHandler}>
        <span className="sidebar-popup-title">
          Item was added to your cart!
        </span>
        <div className="sidebar-popup-body">
          <button
            className="sidebar-popup-close"
            onClick={hideSidebarPopupHandler}
          >
            <Icon className="icon-times" />
          </button>
          <img
            src={`data:image/[format];base64,${imageUrl}`}
            alt="img"
            className="sidebar-popup-img"
          />
          <div>
            <span className="sidebar-popup-text head">
              {lineItem?.productCategoryDisplayName}
            </span>
            <SelectedOptionsList
              selectedOptions={selectedOptions}
              className="sidebar-popup-text"
            />
            <span className="sidebar-popup-text total">
              {`Total $ ${total}`}
            </span>
          </div>
        </div>
        <div className="sidebar-popup-btns">
          <Button
            className="sidebar-popup-btn btn-secondary"
            clickHandler={continueOrderingHandler}
          >
            Continue Ordering
          </Button>
          <Button
            className="sidebar-popup-btn btn-main"
            clickHandler={switchToCheckout}
          >
            Checkout
          </Button>
        </div>
      </div>
    </>
  );
};

export default SidebarPopup;
