import { IClientRect, ICropObject, ISize } from 'types/editor';
import PercentService from 'utils/digits/percentService';

class CropService extends PercentService {
  getCropObject(
    originalImageSize: ISize,
    imageClientRect: IClientRect,
    productClientRect: IClientRect,
  ): ICropObject {
    const {
      x: imageX,
      y: imageY,
      width: imageWidth,
      height: imageHeight,
    } = imageClientRect;

    const {
      x: prodX,
      y: prodY,
      width: prodWidth,
      height: prodHeight,
    } = productClientRect;

    // new values relatively crop object below
    const prodLeftPoint = {
      x: prodX - imageX,
      y: prodY - imageY,
    };

    const prodCenterPoint = {
      x: prodWidth / 2 + prodLeftPoint.x,
      y: prodHeight / 2 + prodLeftPoint.y,
    };

    const prodCenterXPrecent = this.convertToPercent(
      prodCenterPoint.x,
      imageWidth,
    );

    const prodCenterYPrecent = this.convertToPercent(
      prodCenterPoint.y,
      imageHeight,
    );

    const prodWidthPrecent = this.convertToPercent(
      prodWidth,
      imageWidth,
    );

    const prodHeightPrecent = this.convertToPercent(
      prodHeight,
      imageHeight,
    );

    return {
      pixelWidth: originalImageSize.width,
      pixelHeight: originalImageSize.height,
      cropXPrecent: prodCenterXPrecent,
      cropYPrecent: prodCenterYPrecent,
      cropWidthPrecent: prodWidthPrecent,
      cropHeightPrecent: prodHeightPrecent,
    };
  }

  calcImageCrop(crop: ICropObject, width: number, height: number) {
    const {
      cropXPrecent,
      cropYPrecent,
      cropWidthPrecent,
      cropHeightPrecent,
    } = crop;

    const centerX = this.convertFromPercent(cropXPrecent, width);
    const centerY = this.convertFromPercent(cropYPrecent, height);

    const newWidth = this.convertFromPercent(cropWidthPrecent, width);
    const newHeight = this.convertFromPercent(
      cropHeightPrecent,
      height,
    );

    const cropX = centerX - newWidth / 2;
    const cropY = centerY - newHeight / 2;

    return {
      x: cropX,
      y: cropY,
      width: newWidth,
      height: newHeight,
    };
  }
}

export default new CropService();
