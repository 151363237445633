import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useAppSelector from 'hooks/redux/useAppSelector';

import ContentButton from 'components/Buttons/ContentButton';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icons/Icon';

import { EditorWindowContext } from 'context/contexts/editor/general';
import { SECOND_FAZE } from 'constants/temp';
import { Event } from 'types/general';
import {
  isSelectedProductSizeOptionSelector,
  productCategorySelector,
} from 'redux/sidePanel';

import MenuList from './MenuList';
import FooterSettings from '../Footer/FooterSettings';
import { isDarkThemeSelector } from '../../../../redux/theme';

const Menu: FC = () => {
  const [isOpenedSettings, setIsOpenedSettings] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const isDarkTheme = useAppSelector(isDarkThemeSelector);
  const currentProductCategory = useAppSelector(
    productCategorySelector,
  );
  const { showEditorWindowHandler: showEditorWindow } = useContext(
    EditorWindowContext,
  );

  const isSelectedSizeOption = useAppSelector(
    isSelectedProductSizeOptionSelector,
  );

  const { isMobile } = useDefineDeviceByWindowSize();

  const hideMenu = useCallback(() => setIsOpen(false), []);

  const toggleMenu = useCallback(
    (e: Event<HTMLButtonElement>) => {
      e.stopPropagation();

      setIsOpen(!isOpen);
    },
    [isOpen],
  );

  const hideMenuSettings = useCallback(
    () => setIsOpenedSettings(false),
    [],
  );

  const showEditorWindowHandler = useCallback(() => {
    if (!isSelectedSizeOption) return;

    showEditorWindow(true);
  }, [isSelectedSizeOption, showEditorWindow]);

  const showMenuSettings = () => setIsOpenedSettings(true);

  const stopPropagation = (e: Event<HTMLDivElement>) =>
    e.stopPropagation();

  useEffect(() => {
    document.addEventListener('click', hideMenu);

    return () => document.removeEventListener('click', hideMenu);
  }, [hideMenu]);

  const mobileCropBtnStyle =
    isMobile && isDarkTheme ? 'menu-btn' : 'menu-btn is-dark';

  return (
    <div className="menu">
      <div className="menu-btn-container">
        <ContentButton className="menu-btn" clickHandler={toggleMenu}>
          <Icon className="menu-icon icon-menu" />
        </ContentButton>
      </div>
      <span className="menu-title">
        {currentProductCategory?.displayName}
      </span>
      {isMobile && (
        <Button
          className={mobileCropBtnStyle}
          clickHandler={showEditorWindowHandler}
        >
          <Icon className="menu-icon icon-crop" />
        </Button>
      )}
      {!isMobile && SECOND_FAZE && (
        <div className="menu-controls">
          <Button
            className="menu-controls-btn"
            isDisabled
            isSecondary
          >
            <Icon className="menu-controls-icon icon-grid" /> Select
            Preset
          </Button>
          <Button className="menu-controls-btn" isSecondary>
            <Icon className="menu-controls-icon icon-bookmark" />
            Save Preset
          </Button>
        </div>
      )}
      {isOpen && (
        <>
          <div className="shadow" />
          <div
            onClick={stopPropagation}
            className={cn('menu-list', {
              'is-opened': isOpen,
            })}
          >
            <div className="menu-list-header">
              <Button className="menu-list-header-btn" isSecondary>
                <a href="http" className="header-logo-container">
                  <Icon className="icon-logo menu-header-logo" />
                </a>
              </Button>
              <Button
                className="menu-list-header-btn"
                isSecondary
                clickHandler={toggleMenu}
              >
                <Icon className="menu-list-header-icon icon-previous" />
              </Button>
            </div>
            {!isOpenedSettings && <MenuList />}
            {isOpenedSettings && (
              <>
                <Button
                  className="menu-list-header-btn"
                  isSecondary
                  clickHandler={hideMenuSettings}
                >
                  <Icon className="menu-list-header-icon icon-previous" />
                </Button>
                <FooterSettings />
              </>
            )}
            {!isOpenedSettings && (
              <div
                className="menu-list-item menu-footer"
                onClick={showMenuSettings}
              >
                <Icon className="menu-footer-icon icon-menu-settings" />
                Settings
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Menu;
