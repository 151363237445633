import { useCallback, useEffect, useState } from 'react';

import shapePointsService from 'utils/editor/coordinates/ShapePointsService';
import imageSizeService from 'utils/editor/sizes/ImageSizeService';

import { originalCroppedImageSizeSelector } from 'redux/editor';
import { removeActiveImageObject } from 'redux/gallery';
import {
  setIsResetCroppedElement,
  setIsResetCustomCorners,
} from 'redux/editor/editorReducer';
import {
  getProductAsync,
  isSelectedProductSizeOptionSelector,
  setShowOptions,
} from 'redux/sidePanel';

import { STAGE_PADDING } from 'constants/editor/general';
import { WINDOW_SIZES } from 'constants/windowSizes';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import useResetEditor from '../useResetEditor';
import useEditMode from '../useEditMode';

const marginsByHeight = 20;
const paddingsByWidth = 30;

const useCanvasResponsive = (): void => {
  const [prevWindowWidth, setPrevWindowWidth] = useState(0);

  const { stageRef, croppedImageRef } = useEditorRefs();

  const originalCroppedImageSize = useAppSelector(
    originalCroppedImageSizeSelector,
  );
  const isSelectedProductSize = useAppSelector(
    isSelectedProductSizeOptionSelector,
  );
  const dispatch = useAppDispatch();

  const { isDesktop } = useDefineDeviceByWindowSize();
  const [, setIsEditMode] = useEditMode();

  const resetEditor = useResetEditor();

  const hardResetEditor = useCallback(() => {
    setIsEditMode(false);
    resetEditor();
    dispatch(getProductAsync({}));
    dispatch(setShowOptions(true));
    dispatch(setIsResetCroppedElement(true));
    dispatch(setIsResetCustomCorners(true));
  }, [resetEditor, dispatch, setIsEditMode]);

  const calcStageSize = useCallback(() => {
    const $sidebar = document.querySelector('.sidebar');
    const $header = document.querySelector('.header');
    const $footer = document.querySelector('.footer');

    if (!$header || !$footer || !$sidebar) return null;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const { width: sidebarWidth } = $sidebar.getBoundingClientRect();
    const { height: headerHeight } = $header.getBoundingClientRect();
    const { height: footerHeight } = $footer.getBoundingClientRect();

    const neededStageWidth =
      windowWidth - paddingsByWidth - sidebarWidth;
    const neededStageHeight =
      windowHeight - marginsByHeight - headerHeight - footerHeight;

    const nextSize = {
      width: Math.round(neededStageWidth),
      height: Math.round(neededStageHeight),
    };

    return nextSize;
  }, []);

  const resizeHandler = useCallback(() => {
    const croppedImage = croppedImageRef.current;
    const stage = stageRef.current;

    const currentWindowWidth = window.innerWidth;

    const { TABLET } = WINDOW_SIZES;

    if (!isDesktop) {
      hardResetEditor();
      return dispatch(removeActiveImageObject());
    }

    if (isSelectedProductSize) {
      hardResetEditor();
    }

    const lessThanDesktop =
      prevWindowWidth >= TABLET && currentWindowWidth < TABLET;

    if (lessThanDesktop) {
      hardResetEditor();
      dispatch(removeActiveImageObject());
    }

    setPrevWindowWidth(currentWindowWidth);

    const nextStageSize = calcStageSize();

    if (
      !stage ||
      !croppedImage ||
      !nextStageSize ||
      !originalCroppedImageSize
    )
      return null;

    stage.size(nextStageSize);

    const responsiveImageSize =
      imageSizeService.getResponsiveImageSizeByOrientation(
        originalCroppedImageSize,
        nextStageSize,
        STAGE_PADDING,
      );

    croppedImage.size(responsiveImageSize);

    const centeredImagePos =
      shapePointsService.getShapeAlignmentPoint(
        nextStageSize,
        responsiveImageSize,
      );

    return croppedImage.setAbsolutePosition(centeredImagePos);
  }, [
    calcStageSize,
    dispatch,
    croppedImageRef,
    hardResetEditor,
    isDesktop,
    isSelectedProductSize,
    originalCroppedImageSize,
    stageRef,
    prevWindowWidth,
  ]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);
};

export default useCanvasResponsive;
