import { useCallback, useContext, useEffect } from 'react';

import getActualShapeSize from 'utils/editor/intersectionChecks/getActualShapeSize';
import scaleFromCenterPoint from 'utils/editor/scale/scaleFromCenterPoint';
import productOptionService from 'utils/sidePanel/ProductOptionService';
import calcCroppedImageDpi from 'utils/editor/dpi/calcCroppedImageDpi';
import cropService from 'utils/editor/crop/CropService';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import { ILineItemAppDataJson } from 'types/cart';
import { ICropObject } from 'types/editor';

import {
  setActiveImageObject,
  activeImageObjectSelector,
  imageObjectsSelector,
} from 'redux/gallery';
import {
  setCroppedImageDpi,
  setIsSetupEditorView,
  setIsSetupSidePanel,
  setMaxSlideValue,
} from 'redux/editor/editorReducer';
import {
  getProductAsync,
  isSelectedOrientationSelector,
  selectedProductSizeOptionSelector,
} from 'redux/sidePanel';
import {
  isSetupEditorViewSelector,
  isSetupSidePanelSelector,
  lineItemSelector,
  originalCroppedImageSizeSelector,
} from 'redux/editor';
import { setColor } from 'redux/editor/colorPicker';

import { CropUpdateContext } from 'context/contexts/editor/canvas';

import usePrevValues from './intersections/usePrevValues';
import useRotation from './rotation/useRotation';
import useOrientation from './useOrientation';
import useEditMode from './useEditMode';

const useEditLineItemMode = (): void => {
  const { imageWrapperRef, croppedImageRef, stageRef, mainLayerRef } =
    useEditorRefs();
  const cropUpdate = useContext(CropUpdateContext);

  const isSetupEditorView = useAppSelector(isSetupEditorViewSelector);
  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const imageObjects = useAppSelector(imageObjectsSelector);
  const lineItem = useAppSelector(lineItemSelector);
  const originalCroppedImageSize = useAppSelector(
    originalCroppedImageSizeSelector,
  );
  const isSelectedOrientation = useAppSelector(
    isSelectedOrientationSelector,
  );
  const selectedSize = useAppSelector(
    selectedProductSizeOptionSelector,
  );
  const isSetupSidePanel = useAppSelector(isSetupSidePanelSelector);

  const dispatch = useAppDispatch();

  const [, setIsEditMode] = useEditMode();
  const { setPrevValues } = usePrevValues();
  const [, setRotation] = useRotation();
  const changeOrientationHandler = useOrientation();

  const setupEditorView = useCallback(
    (
      crop: ICropObject,
      lineItemAppDataJson: ILineItemAppDataJson[],
      rotation: number,
      productOptionId: string,
    ) => {
      const imageWrapper = imageWrapperRef.current;
      const croppedImage = croppedImageRef.current;
      const mainLayer = mainLayerRef.current;
      const stage = stageRef.current;

      if (
        !imageWrapper ||
        !croppedImage ||
        !activeImageObject ||
        !selectedSize ||
        !stage ||
        !mainLayer
      )
        return;

      const { size } = selectedSize;

      if (!size) return;

      const widthInch = size?.width || 0;
      const heightInch = size?.height || 0;

      const lineItemAppData: any = lineItemAppDataJson;

      const [imageScale, layerScale] = lineItemAppData;

      setIsEditMode(true);
      changeOrientationHandler(productOptionId, size);

      const actualImageWrapSize = getActualShapeSize(
        imageWrapper.size(),
        imageScale.value,
        layerScale.value,
      );

      const imageCrop = cropService.calcImageCrop(
        crop,
        actualImageWrapSize.width,
        actualImageWrapSize.height,
      );

      const currentLayerScale =
        mainLayer.scaleX() - layerScale.value.x;

      const currentImgWScale =
        imageWrapper.scaleX() - imageScale.value.x;

      scaleFromCenterPoint({
        scaleObject: mainLayer,
        isZoomOut: true,
        scaleX: currentLayerScale,
      });

      scaleFromCenterPoint({
        scaleObject: imageWrapper,
        isZoomOut: true,
        scaleX: currentImgWScale,
      });

      const croppedImageAbsPos = croppedImage.getAbsolutePosition();

      const posDiff = {
        x: croppedImageAbsPos.x - imageCrop.x,
        y: croppedImageAbsPos.y - imageCrop.y,
      };

      imageWrapper.setAbsolutePosition(posDiff);

      setRotation(rotation);

      const croppedImageSize = croppedImage.size();
      const imageWrapperSize = imageWrapper.size();

      const widthDiff = Math.abs(
        imageWrapperSize.width - croppedImageSize.width,
      );
      const heightDiff = Math.abs(
        imageWrapperSize.height - croppedImageSize.height,
      );

      const isVertical = widthDiff >= heightDiff;

      const imgWrapperSize = isVertical
        ? imageWrapper.height()
        : imageWrapper.width();

      const imgWrapperWidth = imageWrapper.scaleX() * imgWrapperSize;

      const maxDpi = calcCroppedImageDpi(
        isVertical
          ? activeImageObject.originalImageHeightInPixels
          : activeImageObject.originalImageWidthInPixels,
        isVertical ? +heightInch : +widthInch,
        imgWrapperWidth,
        isVertical ? croppedImage.height() : croppedImage.width(),
      );

      const dpi = calcCroppedImageDpi(
        isVertical
          ? activeImageObject.originalImageHeightInPixels
          : activeImageObject.originalImageWidthInPixels,
        isVertical ? +heightInch : +widthInch,
        imgWrapperWidth,
        isVertical ? croppedImage.height() : croppedImage.width(),
      );

      cropUpdate();
      setPrevValues();
      dispatch(setIsSetupEditorView(true));
      dispatch(setMaxSlideValue(maxDpi));
      dispatch(setCroppedImageDpi(dpi));
    },
    [
      changeOrientationHandler,
      stageRef,
      activeImageObject,
      cropUpdate,
      croppedImageRef,
      dispatch,
      imageWrapperRef,
      selectedSize,
      setIsEditMode,
      setPrevValues,
      setRotation,
      mainLayerRef,
    ],
  );

  // setup image
  useEffect(() => {
    if (!lineItem || activeImageObject) return;

    const { sides } = lineItem;

    const imageGuid = sides?.[0].imageGuid;

    if (!imageGuid) return;

    const imageObject = imageObjects.find(
      (imageObj) => imageObj.imageGuid === imageGuid,
    );

    if (!imageObject) return;

    dispatch(setActiveImageObject(imageObject));
  }, [dispatch, lineItem, imageObjects, activeImageObject]);

  // setup side panel
  useEffect(() => {
    if (!lineItem || isSetupSidePanel) return;

    const {
      selectedOptions,
      productCategory: productCategoryTypeParam,
      quantity,
    } = lineItem;

    if (!quantity || !isSelectedOrientation) return;

    const color =
      selectedOptions.find(
        ({ productOptionGroupId }) =>
          productOptionGroupId === 'EdgeColor',
      )?.productOptionDynamicValue || '';
    dispatch(setColor(color));

    const preparedOptions =
      productOptionService.prepareSelectedOptions(selectedOptions);

    const addAvailableEdgeColor = preparedOptions.map((el) => {
      if (el.productOptionGroupId === 'EdgeColor') {
        return { ...el, productOptionDynamicValue: color };
      }
      return el;
    });

    const requestBodyParam = {
      quantity,
      selectedOptions: addAvailableEdgeColor,
    };

    dispatch(
      getProductAsync({
        requestBodyParam,
        productCategoryTypeParam,
      }),
    );

    dispatch(setIsSetupSidePanel(true));
  }, [dispatch, lineItem, isSelectedOrientation, isSetupSidePanel]);

  // setup editor view
  useEffect(() => {
    if (!lineItem || !originalCroppedImageSize || isSetupEditorView)
      return;

    const { sides, lineItemAppDataJson, selectedOptions } = lineItem;

    if (!sides || !lineItemAppDataJson) return;

    const { crop, rotation } = sides[0];
    const [orientation] = selectedOptions;
    const { productOptionId } = orientation;

    setupEditorView(
      crop,
      lineItemAppDataJson,
      rotation,
      productOptionId,
    );
  }, [
    isSetupEditorView,
    lineItem,
    originalCroppedImageSize,
    setupEditorView,
  ]);
};

export default useEditLineItemMode;
