import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import * as url from 'url';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useEditLineItemMode from 'hooks/editor/useEditLineItemMode';
import useAppSelector from 'hooks/redux/useAppSelector';

import useUpload from 'hooks/useUpload';
import { Event } from 'types/general';
import {
  EditorWindowContext,
  SettingsContext,
  SidebarPopupContext,
} from 'context/contexts/editor/general';

import {
  activeImageObjectSelector,
  isGalleryPendingSelector,
  isShowGallerySelector,
} from 'redux/gallery';
import {
  productCategoriesStatusSelector,
  productCategorySelector,
  workspaceSelector,
} from 'redux/sidePanel';
import { Workspace } from 'redux/sidePanel/types';
import { isDarkThemeSelector } from 'redux/theme';
import EditorControls from '../EditorControls';
import CanvasMobile from '../CanvasMobile';
import Gallery from '../Gallery';
import Upload from '../Upload';

const MainContent: FC = () => {
  const [selectedWorkspaceURL, setSelectedWorkspaceURL] = useState<
    string | null
  >(null);

  const { showSidebarPopupHandler } = useContext(SidebarPopupContext);
  const { showEditorWindow } = useContext(EditorWindowContext);
  const { showSettingsHandler } = useContext(SettingsContext);

  const isDarkTheme = useAppSelector(isDarkThemeSelector);
  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const isShowGallery = useAppSelector(isShowGallerySelector);
  const workspaceImages = useAppSelector(workspaceSelector);
  const isGalleryPending = useAppSelector(isGalleryPendingSelector);

  const [rootProps, inputProps, isDragActive] = useUpload();
  const { isMobile } = useDefineDeviceByWindowSize();

  // edit line item mode
  useEditLineItemMode();

  useEffect(() => {
    const getWorkspaceImageUrl = (images: Workspace[]): string => {
      switch (true) {
        case !isMobile && isDarkTheme:
          return images[0].workspaceImages[0]?.url;

        case !isMobile && !isDarkTheme:
          return images[0].workspaceImages[1]?.url;

        case isMobile && isDarkTheme:
          return images[0].workspaceImages[2]?.url;

        case isMobile && !isDarkTheme:
          return images[0].workspaceImages[3]?.url;

        default:
          return images[0].workspaceImages[1]?.url;
      }
    };

    setSelectedWorkspaceURL(
      getWorkspaceImageUrl(workspaceImages) || null,
    );
  }, [workspaceImages, isDarkTheme, isMobile]);

  const hidePopupsHandler = useCallback(
    (e: Event<HTMLElement>) => {
      e.stopPropagation();
      showSettingsHandler(false);
      showSidebarPopupHandler(false);
    },
    [showSettingsHandler, showSidebarPopupHandler],
  );

  const isShowUpload = !isGalleryPending && !activeImageObject;
  const isShowEditor = !showEditorWindow && activeImageObject;

  return (
    <div className="content col">
      <div
        className={cn('content-container', {
          'is-active': !!activeImageObject,
          'is-drag-active': isDragActive,
          'is-loader': isGalleryPending,
        })}
        style={{
          backgroundImage: `url(${
            isShowUpload ? selectedWorkspaceURL : ''
          })`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        {isGalleryPending && <div className="loader" />}
        {isShowUpload && (
          <Upload
            rootProps={rootProps}
            inputProps={inputProps}
            hidePopupsHandler={hidePopupsHandler}
          />
        )}
        {isShowEditor &&
          (!isMobile ? <EditorControls /> : <CanvasMobile />)}
      </div>
      {isShowGallery && (
        <Gallery hidePopupsHandler={hidePopupsHandler} />
      )}
    </div>
  );
};

export default MainContent;
