import { Stage, Layer, Image, Shape, Rect, Group } from 'react-konva';
import { PresetColor } from 'react-color/lib/components/sketch/Sketch';
import { FC, useCallback, useEffect } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { Html } from 'react-konva-utils';
import useImage from 'use-image';

import usePreventDragIntersections from 'hooks/editor/usePreventDragIntersections';
import useImageWrapperTouchZoom from 'hooks/editor/zoom/useImageWrapperTouchZoom';
import useImageWrapperPointers from 'hooks/editor/view/useImageWrapperPointers';
import useImageColorPresets from 'hooks/editor/colors/useImageColorPresets';
import useSetupRotateIcons from 'hooks/editor/rotation/useSetupRotateIcons';
import useMainLayerTouchZoom from 'hooks/editor/zoom/useMainLayerTouchZoom';
import useSetupColorWraps from 'hooks/editor/view/useSetupColorWraps';
import useCroppedImageDpi from 'hooks/editor/view/useCroppedImageDpi';
import useDefineOrientation from 'hooks/editor/useDefineOrientation';
import useStageInteractions from 'hooks/editor/useStageInteractions';
import useMouseWheelZoom from 'hooks/editor/zoom/useMouseWheelZoom';
import useRenderProduct from 'hooks/editor/view/useRenderProduct';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppSelector from 'hooks/redux/useAppSelector';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useEditMode from 'hooks/editor/useEditMode';

import shapePointsService from 'utils/editor/coordinates/ShapePointsService';
import imageSizeService from 'utils/editor/sizes/ImageSizeService';
import getImageUrlBySize from 'utils/gallery/getImageUrlBySize';
import RBGAToHex from 'utils/editor/RGBAToHex';

import { productCategories } from 'constants/sidePanel/productCategories';
import IMAGE_SIZE_INDEXES from 'constants/gallery/imageSizeIndexes';
import customCorners from 'constants/editor/customCorners';
import { STAGE_PADDING } from 'constants/editor/general';
import rotateIcons from 'constants/editor/rotateIcons';
import {
  BLACK_COLOR,
  mostCommonColorConfig,
  presetsColorConfig,
  WHITE_COLOR,
} from 'constants/editor/canvasWraps';

import { activeImageObjectSelector } from 'redux/gallery';
import {
  setCroppedImageDpi,
  setMaxSlideValue,
  setOriginalCroppedImageSize,
  setStartImageWrapperScale,
} from 'redux/editor/editorReducer';
import {
  colorSelector,
  eyeDropperModeSelector,
  setColor,
  setShowColorPicker,
  showColorPickerSelector,
} from 'redux/editor/colorPicker';
import {
  isColorEdgeSelector,
  productCategoryTypeSelector,
} from 'redux/sidePanel';
import {
  isResetCroppedElementSelector,
  originalCroppedImageSizeSelector,
} from 'redux/editor';

import Icon from 'components/Icons/Icon';

import rotateIcon from 'assets/img/rotate.png';
import { KonvaEventObject } from 'konva/lib/Node';
import getNewIntersectionPos from 'utils/editor/intersectionChecks/getNewIntersectionPos';

const Canvas: FC = () => {
  const {
    stageRef,
    imageRef,
    mainLayerRef,
    imageWrapperRef,
    croppedImageRef,
    croppedElementRef,
  } = useEditorRefs();

  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const showColorPicker = useAppSelector(showColorPickerSelector);
  const eyeDropperMode = useAppSelector(eyeDropperModeSelector);
  const isColorEdge = useAppSelector(isColorEdgeSelector);
  const originalCroppedImageSize = useAppSelector(
    originalCroppedImageSizeSelector,
  );
  const isResetCroppedElement = useAppSelector(
    isResetCroppedElementSelector,
  );
  const productCategoryType = useAppSelector(
    productCategoryTypeSelector,
  );
  const color = useAppSelector(colorSelector);

  const activeImageUrl = getImageUrlBySize(
    activeImageObject,
    IMAGE_SIZE_INDEXES.LARGE,
  );

  const [activeImage] = useImage(activeImageUrl, 'anonymous');
  const [arrowIcon] = useImage(rotateIcon);

  const dispatch = useAppDispatch();

  const dragMoveHandler = usePreventDragIntersections();
  const [, calcDpi] = useCroppedImageDpi();
  const [isEditMode] = useEditMode();
  const { customCornersRefs, rotateIconsRefs } =
    useSetupRotateIcons();
  const { renderAcrylicPrint, renderMetalPrint, renderCanvasWraps } =
    useRenderProduct();
  const { colorWrapsRefs } = useSetupColorWraps();
  const presetColors =
    (useImageColorPresets(
      activeImageUrl,
      presetsColorConfig,
    ) as string[]) || [];

  const mostUsedColor = useImageColorPresets(
    activeImageUrl,
    mostCommonColorConfig,
  ) as string;

  // setup environment hooks
  useStageInteractions(eyeDropperMode);
  useDefineOrientation();

  // hooks related to zoom
  useMouseWheelZoom();
  useImageWrapperTouchZoom();
  useMainLayerTouchZoom();

  // add user-friendly pointers to image wrapper
  useImageWrapperPointers();

  const showColorPickerLayout =
    showColorPicker && isEditMode && isColorEdge;

  const sceneFunc = useCallback(
    (ctx, shape) => {
      const croppedElement = croppedElementRef.current;

      if (!activeImage || !croppedElement) return;

      const [acrylicPrint, metalPrint, canvasWrap] =
        productCategories;

      let width = shape.width();
      let height = shape.height();

      ctx.beginPath();

      switch (productCategoryType) {
        case metalPrint.productCategoryType:
          renderMetalPrint(ctx, width, height);
          break;

        case acrylicPrint.productCategoryType:
          renderAcrylicPrint(ctx, width, height);
          break;

        case canvasWrap.productCategoryType:
          renderCanvasWraps(ctx, width, height);
          break;

        default:
          renderMetalPrint(ctx, width, height);
          break;
      }

      ctx.closePath();

      ctx.clip();

      if (!isResetCroppedElement) {
        width = croppedElement.width();
        height = croppedElement.height();

        const matrix = croppedElement.getAbsoluteTransform().m;

        ctx.transform(...matrix);
      }

      ctx.drawImage(activeImage, 0, 0, width, height);
    },
    [
      activeImage,
      renderMetalPrint,
      renderCanvasWraps,
      renderAcrylicPrint,
      croppedElementRef,
      productCategoryType,
      isResetCroppedElement,
    ],
  );

  const onEyeDropperClick = (e: any) => {
    if (!eyeDropperMode || !color) return;

    const { target } = e;

    const id = target.id();

    const x = e.evt.layerX;
    const y = e.evt.layerY;

    if (id !== 'product' && id !== 'image') return;

    const ctx = target.getContext();

    const pxData = ctx.getImageData(x, y, 1, 1);

    const rgb = `rgb(${pxData.data[0]},${pxData.data[1]},${pxData.data[2]})`;

    const hex = RBGAToHex(rgb);

    dispatch(setColor(hex));
  };

  const onChange = (colorParam: ColorResult) => {
    dispatch(setColor(colorParam.hex));
  };

  const hideColorPicker = () => {
    dispatch(setShowColorPicker(false));
  };

  const onDragEnd = () => {
    const stage = stageRef.current;

    const container = stage?.container();

    if (!container) return;

    container.style.cursor = 'default';
  };

  const onMouseLeave = () => {
    const stage = stageRef.current;

    const container = stage?.container();

    if (!container) return;

    container.style.cursor = 'default';
  };

  const onMouseEnter = () => {
    const stage = stageRef.current;

    const container = stage?.container();

    if (!container) return;

    container.style.cursor = 'grab';
  };

  const onDragMove = (e: KonvaEventObject<DragEvent>) => {
    const stage = e.target.getStage();
    const container = stage?.container();

    if (!container || !stage) return;

    const target = e.currentTarget;

    container.style.cursor = 'grabbing';

    const newPos = getNewIntersectionPos(
      target.position(),
      target.size(),
      stage.size(),
    );

    target.setAbsolutePosition(newPos);
  };

  const CustomCorners = customCorners.map(
    ({ id, width, height, fill, opacity }, index) => (
      <Rect
        id={id}
        key={id}
        fill={fill}
        width={width}
        height={height}
        opacity={opacity}
        ref={customCornersRefs[index]}
      />
    ),
  );

  const RotateIcons = rotateIconsRefs.map((iconRef, index) => {
    const { id, width, height, visible, rotation } =
      rotateIcons[index];

    return (
      <Image
        key={id}
        id={id}
        ref={iconRef}
        image={arrowIcon}
        width={width}
        height={height}
        visible={visible}
        rotation={rotation}
      />
    );
  });

  const ColorWrapsRects = colorWrapsRefs.map(({ rectRef, id }) => (
    <Rect key={id} ref={rectRef} fill={color} visible={isEditMode} />
  ));

  // set original product image size
  useEffect(() => {
    if (!activeImage) return;

    const originalImageSize = {
      width: activeImage.naturalWidth,
      height: activeImage.naturalHeight,
    };

    dispatch(setOriginalCroppedImageSize(originalImageSize));
  }, [dispatch, activeImage]);

  // setup cropped image size
  useEffect(() => {
    const croppedImage = croppedImageRef.current;
    const stage = stageRef.current;

    if (!stage || !croppedImage || !originalCroppedImageSize) return;

    const responsiveImageSize =
      imageSizeService.getResponsiveImageSizeByOrientation(
        originalCroppedImageSize,
        stage.size(),
        STAGE_PADDING,
      );

    croppedImage.size(responsiveImageSize);

    const centeredImagePos =
      shapePointsService.getShapeAlignmentPoint(
        stage.size(),
        responsiveImageSize,
      );

    croppedImage.setAbsolutePosition(centeredImagePos);
  }, [stageRef, croppedImageRef, originalCroppedImageSize]);

  // set needed values after selecting size option
  useEffect(() => {
    const imageWrapper = imageWrapperRef.current;

    if (!imageWrapper) return;

    const dpi = calcDpi();

    const imageWrapperScale = imageWrapper.scale();

    if (!imageWrapperScale) return;

    dispatch(setCroppedImageDpi(dpi));
    dispatch(setMaxSlideValue(dpi));
    dispatch(setStartImageWrapperScale(imageWrapperScale));
  }, [calcDpi, dispatch, imageWrapperRef]);

  // add drag handler for imageWrapper
  useEffect(() => {
    const imageWrapper = imageWrapperRef.current;

    if (!imageWrapper) return () => {};

    imageWrapper.on('dragmove', dragMoveHandler);

    return () => imageWrapper.off('dragmove', dragMoveHandler);
  }, [dragMoveHandler, imageWrapperRef]);

  // set active image most common used color
  useEffect(() => {
    dispatch(setColor(mostUsedColor as string));
  }, [dispatch, mostUsedColor]);

  return (
    <Stage id="stage" ref={stageRef} onClick={onEyeDropperClick}>
      <Layer ref={mainLayerRef}>
        <Image
          id="product"
          ref={croppedImageRef}
          image={activeImage}
          sceneFunc={sceneFunc}
        />
        <Group
          ref={imageWrapperRef}
          draggable={!eyeDropperMode}
          visible={isEditMode}
        >
          <Image
            id="image"
            ref={imageRef}
            image={activeImage}
            opacity={0.15}
          />
          {CustomCorners}
          {RotateIcons}
        </Group>
        {isColorEdge && ColorWrapsRects}
      </Layer>
      <Layer>
        {showColorPickerLayout && (
          <Group
            draggable
            x={800}
            y={100}
            width={250}
            height={316}
            onDragMove={onDragMove}
            onDragEnd={onDragEnd}
          >
            <Html>
              <div className="test">
                <SketchPicker
                  onChange={onChange}
                  disableAlpha
                  presetColors={[
                    ...presetColors,
                    BLACK_COLOR,
                    WHITE_COLOR,
                  ]}
                  color={color}
                />
              </div>
              <Icon
                className="color-picker-close-icon icon-close"
                clickHandler={hideColorPicker}
              />
            </Html>
            <Rect
              x={0}
              y={0}
              width={250}
              height={316}
              fill="white"
              cornerRadius={10}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          </Group>
        )}
        <Shape ref={croppedElementRef} />
      </Layer>
    </Stage>
  );
};

export default Canvas;
