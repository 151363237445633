import { FC, useContext, useEffect, useState } from 'react';

import useImageColorPresets from 'hooks/editor/colors/useImageColorPresets';
import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useImageWrapperZoom from 'hooks/editor/zoom/useImageWrapperZoom';
import useCroppedImageDpi from 'hooks/editor/view/useCroppedImageDpi';
import useCreateCropObject from 'hooks/editor/useCreateCropObject';
import getImageUrlBySize from 'utils/gallery/getImageUrlBySize';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useProductSize from 'hooks/sidePanel/useProductSize';
import useRotation from 'hooks/editor/rotation/useRotation';
import useEditorState from 'hooks/editor/useEditorState';
import useResetEditor from 'hooks/editor/useResetEditor';
import useAppSelector from 'hooks/redux/useAppSelector';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useEditMode from 'hooks/editor/useEditMode';
import { TicksSlider } from 'components/TicksSlider';
import { RangeSlider } from 'components/RangeSlider';
import Icon from 'components/Icons/Icon';
import Button from 'components/Buttons/Button';

import {
  setCropObject,
  setCroppedImageDpi,
  setCroppedImageURL,
  setIsResetCroppedElement,
  setIsResetCustomCorners,
  setMaxSlideValue,
  setPrevEditorState,
  setSavedSlideValue,
  setStartImageWrapperScale,
} from 'redux/editor/editorReducer';
import {
  setEditorMobileRotation,
  setEditorMobileCropObject,
  setEditorMobileImageScale,
} from 'redux/editorMobile';
import { activeImageObjectSelector } from 'redux/gallery';
import {
  getProductAsync,
  selectedProductSizeOptionSelector,
  isColorEdgeSelector,
} from 'redux/sidePanel';
import {
  maxSlideValueSelector,
  prevEditorStateSelector,
  savedSlideValueSelector,
  startImageWrapperScaleSelector,
} from 'redux/editor';
import {
  setColor,
  colorSelector,
  hideColorPickerMobileSelector,
  setHideColorPickerMobile,
} from 'redux/editor/colorPicker';

import {
  DPI_LIMIT,
  lessDpiMessage,
  editorConstants,
  MIN_SLIDER_VALUE,
  MAX_SLIDER_VALUE,
  SLIDER_STEP,
} from 'constants/editor/general';
import {
  BLACK_COLOR,
  presetsColorConfig,
  WHITE_COLOR,
} from 'constants/editor/canvasWraps';
import IMAGE_SIZE_INDEXES from 'constants/gallery/imageSizeIndexes';
import {
  ConfirmPopupContext,
  EditorWindowContext,
} from 'context/contexts/editor/general';

import { ColorResult, SketchPicker } from 'react-color';
import { PresetColor } from 'react-color/lib/components/sketch/Sketch';
import Canvas from '../Canvas';

export const EditModal: FC = () => {
  const savedSlideValue = useAppSelector(savedSlideValueSelector);

  const [isTickSlider, setIsTickSlider] = useState(true);
  const [slideValue, setSlideValue] = useState(savedSlideValue);
  const [prevSlideValue, setPrevSlideValue] =
    useState(savedSlideValue);

  const setConfirmPopupState = useContext(ConfirmPopupContext);
  const { showEditorWindowHandler } = useContext(EditorWindowContext);
  const { imageWrapperRef, croppedImageRef } = useEditorRefs();
  const [, undoEditorChanges] = useEditorState();

  const maxSlideValue = useAppSelector(maxSlideValueSelector);
  const startImageWrapperScale = useAppSelector(
    startImageWrapperScaleSelector,
  );
  const selectedProductSize = useAppSelector(
    selectedProductSizeOptionSelector,
  );
  const color = useAppSelector(colorSelector);
  const isEdgeColor = useAppSelector(isColorEdgeSelector);
  const prevEditorState = useAppSelector(prevEditorStateSelector);

  const dispatch = useAppDispatch();
  const onChangeColor = (colorParam: ColorResult) =>
    dispatch(setColor(colorParam.hex));
  const [dpi, calcDpi] = useCroppedImageDpi();
  const createCropObject = useCreateCropObject();
  const [rotation, setRotation] = useRotation();
  const [isEditMode, setIsEditMode] = useEditMode();
  const resetEditor = useResetEditor();
  const applyProductSize = useProductSize();
  const [saveEditorState] = useEditorState();
  const getCropObject = useCreateCropObject();
  const { isMobile } = useDefineDeviceByWindowSize();
  const cropObject = getCropObject();
  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const activeImageUrl = getImageUrlBySize(
    activeImageObject,
    IMAGE_SIZE_INDEXES.LARGE,
  );
  const isColorPickerHidden = useAppSelector(
    hideColorPickerMobileSelector,
  );
  const handleShowColorPicker = () => {
    dispatch(setHideColorPickerMobile(!isColorPickerHidden));
  };

  const presetColors =
    (useImageColorPresets(
      activeImageUrl,
      presetsColorConfig,
    ) as string[]) || [];

  const [zoomInImageWrapper, zoomOutImageWrapper] =
    useImageWrapperZoom();

  const isDpiLessLimit = dpi < DPI_LIMIT;

  const slideChangeHandler = (value: number | number[]) => {
    const imageWrapper = imageWrapperRef.current;

    if (!imageWrapper || !startImageWrapperScale || isDpiLessLimit)
      return null;

    const currentScale = imageWrapper.scale();

    if (!currentScale) return null;

    const neededScale =
      (maxSlideValue / -+value) * startImageWrapperScale.x;

    const newScaleValue = Math.abs(currentScale.x - neededScale);
    editorConstants.scaleValue = newScaleValue;
    if (neededScale > currentScale.x) {
      return zoomInImageWrapper(editorConstants.scaleValue);
    }

    return zoomOutImageWrapper(editorConstants.scaleValue);
  };

  const counterclockwiseRotateHandler = () => {
    setRotation(90);
  };

  const min = isDpiLessLimit ? 0 : -maxSlideValue;
  const max = isDpiLessLimit ? 1 : -DPI_LIMIT;
  const value = isDpiLessLimit ? 1 : -dpi;

  const saveHandler = () => {
    if (dpi < DPI_LIMIT && dpi) {
      return setConfirmPopupState({
        confirmHandler: () => {},
        text: lessDpiMessage,
      });
    }
    const imageWrapper = imageWrapperRef.current;
    const croppedImage = croppedImageRef.current;
    if (!croppedImage) return null;

    if (isMobile && imageWrapper && cropObject) {
      dispatch(setEditorMobileCropObject(cropObject));
      dispatch(setEditorMobileRotation(rotation));
      dispatch(
        setEditorMobileImageScale({
          x: imageWrapper.scaleX(),
          y: imageWrapper.scaleY(),
        }),
      );
    }

    dispatch(setCroppedImageURL(croppedImage.toDataURL()));
    dispatch(setIsResetCroppedElement(true));
    dispatch(setIsResetCustomCorners(true));
    createCropObject();
    saveEditorState();
    setIsEditMode(!isEditMode);
    dispatch(setSavedSlideValue(slideValue));
    return showEditorWindowHandler(false);
  };

  const closeWindowHandler = () => {
    dispatch(setIsResetCroppedElement(true));
    dispatch(setIsResetCustomCorners(true));
    setIsEditMode(false);
    showEditorWindowHandler(false);
    dispatch(setCropObject(null));
  };

  const resetHandler = () => {
    dispatch(setIsResetCroppedElement(true));
    dispatch(setIsResetCustomCorners(true));
    setIsEditMode(false);
    dispatch(getProductAsync({}));
    closeWindowHandler();
    dispatch(setCropObject(null));
    dispatch(setPrevEditorState(null));
    dispatch(setSavedSlideValue(0));
  };

  useEffect(() => {
    if (!selectedProductSize) return;

    const { size } = selectedProductSize;

    if (!size) return;

    resetEditor();

    setIsEditMode(true);

    applyProductSize(size);

    setRotation(prevEditorState?.rotation || 0);
  }, [
    applyProductSize,
    resetEditor,
    selectedProductSize,
    setIsEditMode,
    zoomInImageWrapper,
    setRotation,
    prevEditorState,
  ]);

  // todo replace this hook to  custom hook
  // set needed values after selecting size option
  useEffect(() => {
    const imageWrapper = imageWrapperRef.current;
    if (!imageWrapper) return;

    const currentDpi = calcDpi();

    dispatch(setCroppedImageDpi(currentDpi));
    dispatch(setMaxSlideValue(currentDpi));
    dispatch(
      setStartImageWrapperScale({
        x: imageWrapper.scaleX(),
        y: imageWrapper.scaleY(),
      }),
    );
  }, [calcDpi, dispatch, imageWrapperRef]);

  useEffect(() => {
    const imageWrapper = imageWrapperRef.current;

    if (!imageWrapper) return;

    const slideValueDiff = slideValue - prevSlideValue;

    if (imageWrapper.rotation() - slideValueDiff === slideValueDiff)
      return;

    setRotation(slideValueDiff);
    setPrevSlideValue(slideValue);
  }, [slideValue, imageWrapperRef, prevSlideValue, setRotation]);

  return (
    <div className="e-modal">
      <ul className="e-modal-header">
        <li className="e-modal-item">
          <button
            className="e-modal-btn"
            onClick={closeWindowHandler}
          >
            <Icon className="e-modal-icon icon-e-times" />
          </button>
        </li>
        <li className="e-modal-item">
          <Button
            className="icon-undo-container"
            clickHandler={undoEditorChanges}
          >
            <Icon className="e-modal-icon icon-undo" />
          </Button>
        </li>
      </ul>
      <div style={{ width: '100%', height: '100%' }}>
        {isEdgeColor && (
          <span
            className="mobile-color-picker__control"
            onClick={handleShowColorPicker}
          >
            {isColorPickerHidden ? 'Show' : 'Hide'}&nbsp;picker{' '}
          </span>
        )}
        <span
          className="e-modal-dpi"
          style={{ top: '77px', right: '10px' }}
        >
          {dpi} DPI
        </span>
        <Canvas />
      </div>
      {isEdgeColor && !isColorPickerHidden && (
        <div className="test mobile-color-picker">
          <SketchPicker
            onChange={onChangeColor}
            disableAlpha
            presetColors={[...presetColors, BLACK_COLOR, WHITE_COLOR]}
            color={color}
          />
        </div>
      )}
      {isTickSlider ? (
        <TicksSlider
          min={MIN_SLIDER_VALUE}
          max={MAX_SLIDER_VALUE}
          step={SLIDER_STEP}
          value={slideValue}
          onChange={setSlideValue}
        />
      ) : (
        <RangeSlider
          min={min}
          max={max}
          value={value}
          onChange={slideChangeHandler}
        />
      )}
      <ul className="e-modal-footer">
        <li className="e-modal-item">
          <button
            className="e-modal-btn danger"
            onClick={resetHandler}
          >
            reset
          </button>
        </li>
        <li className="e-modal-item e-modal-controls">
          <button
            className="e-modal-btn"
            disabled={isTickSlider}
            onClick={() => setIsTickSlider(true)}
          >
            <Icon className="e-modal-icon icon-angle-rotate" />
          </button>
          <span
            className="footer-controls-angle"
            onClick={counterclockwiseRotateHandler}
          >
            <Icon className="e-modal-icon icon-rotate" />
          </span>
          <button
            className="e-modal-btn"
            disabled={!isTickSlider}
            onClick={() => setIsTickSlider(false)}
          >
            <Icon className="e-modal-icon icon-zoom-bar" />
          </button>
        </li>
        <li className="e-modal-item">
          <button
            className="e-modal-btn medium"
            onClick={saveHandler}
          >
            save
          </button>
        </li>
      </ul>
    </div>
  );
};
