import { useCallback, useContext } from 'react';

import useProductSize from 'hooks/sidePanel/useProductSize';
import useAppSelector from 'hooks/redux/useAppSelector';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';

import { basedOrientationIdSelector } from 'redux/gallery';
import { ISizeString } from 'types/general';

import { CropUpdateContext } from 'context/contexts/editor/canvas';

import scaleFromCenterPoint from 'utils/editor/scale/scaleFromCenterPoint';
import useResetEditor from './useResetEditor';
import useRotation from './rotation/useRotation';
import usePrevValues from './intersections/usePrevValues';

type Result = (productOptionId: string, size: ISizeString) => void;

const useOrientation = (): Result => {
  const cropUpdate = useContext(CropUpdateContext);
  const { setPrevValues } = usePrevValues();

  const basedOrientationId = useAppSelector(
    basedOrientationIdSelector,
  );

  const { imageWrapperRef } = useEditorRefs();
  const applyProductSize = useProductSize();
  const resetEditor = useResetEditor();
  const [visualRotation, setRotation] = useRotation();

  const setRotationAfterVisual = useCallback(() => {
    if (visualRotation) {
      setRotation(visualRotation);
    }
  }, [setRotation, visualRotation]);

  const changeOrientationHandler = useCallback(
    (productOptionId: string, size: ISizeString) => {
      const imageWrapper = imageWrapperRef.current;

      resetEditor();

      const isVerticalBasedOrientation = basedOrientationId === 'V';
      const isVerticalNextOrientation = productOptionId === 'V';

      if (isVerticalBasedOrientation && imageWrapper) {
        if (!isVerticalNextOrientation) {
          const newSize = {
            ...size,
            width: size.height,
            height: size.width,
          };

          applyProductSize(newSize);

          if (!visualRotation) return null;
          setRotationAfterVisual();

          const scaleMultiplier =
            (imageWrapper.width() * imageWrapper.scaleX()) /
            imageWrapper.height();
          const orientationScaleCoefficient =
            imageWrapper.scaleX() - scaleMultiplier;

          scaleFromCenterPoint({
            scaleObject: imageWrapper,
            isZoomOut: true,
            scaleX: orientationScaleCoefficient,
          });

          cropUpdate();
          return setPrevValues();
        }

        const newSize = {
          ...size,
          width: size.width,
          height: size.height,
        };

        applyProductSize(newSize);
        return setRotationAfterVisual();
      }

      if (isVerticalNextOrientation && imageWrapper) {
        const newSize = {
          ...size,
          width: size.height,
          height: size.width,
        };

        applyProductSize(newSize);

        if (!visualRotation) return null;
        setRotationAfterVisual();

        const scaleMultiplier =
          (imageWrapper.height() * imageWrapper.scaleX()) /
          imageWrapper.width();
        const orientationScaleCoefficient =
          imageWrapper.scaleX() - scaleMultiplier;

        scaleFromCenterPoint({
          scaleObject: imageWrapper,
          isZoomOut: true,
          scaleX: orientationScaleCoefficient,
        });

        cropUpdate();
        return setPrevValues();
      }

      const newSize = {
        ...size,
        width: size.width,
        height: size.height,
      };

      applyProductSize(newSize);
      return setRotationAfterVisual();
    },
    [
      applyProductSize,
      resetEditor,
      basedOrientationId,
      visualRotation,
      imageWrapperRef,
      cropUpdate,
      setPrevValues,
      setRotationAfterVisual,
    ],
  );

  return changeOrientationHandler;
};

export default useOrientation;
