import { useCallback, FC } from 'react';
import { useHistory } from 'react-router-dom';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useLoader from 'hooks/context/general/useLoader';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';
import useQuantity from 'hooks/useQuantity';

import SelectedOptionsPriceList from 'components/SelectedOptionsPriceList';
import SelectedOptionsList from 'components/SelectedOptionsList';
import Counter from 'components/Counter';

import { deleteLineItemAsync, updateLineItemAsync } from 'redux/cart';
import { productCategoryTypeSelector } from 'redux/sidePanel';
import { colorSelector } from 'redux/editor/colorPicker';
import {
  setIsEditLineItemMode,
  setLineItemId,
} from 'redux/editor/editorReducer';

import CHECKOUT_ROUTES from 'constants/routes/checkout/routes';
import { ROUTES } from 'constants/routes/app/routes';

import toLowerCaseFirstChar from 'utils/string/toLowerCaseFirstChar';

import { ILineItem } from 'types/cart';

import CartItemControls from '../../CartItemControls';
import CartImage from '../../CartImage';

const CartTableBody: FC<ILineItem> = ({
  lineItemId,
  lineItemTotal,
  lineItemImage,
  selectedOptions,
  quantity: defaultQuantity,
  productCategory,
  productId,
  productCategoryDisplayName,
  sides,
  lineItemAppDataJson,
}) => {
  const { handleShowLoader, handleHideLoader } = useLoader();

  const productCategoryType = useAppSelector(
    productCategoryTypeSelector,
  );
  const color = useAppSelector(colorSelector);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const { isMobile } = useDefineDeviceByWindowSize();

  const originalName = sides?.[0].originalName || '';

  const updateLineItem = useCallback(
    (value: number) => {
      const updatedOptions = selectedOptions.map((el) => {
        if (el.productOptionGroupId === 'EdgeColor') {
          return { ...el, productOptionDynamicValue: color };
        }
        return el;
      });

      const lineItem = {
        lineItemId,
        productCategory,
        quantity: value,
        productId,
        lineItemJson: {
          selectedOptions: updatedOptions,
          sides,
        },
        lineItemAppDataJson,
      };

      dispatch(updateLineItemAsync(lineItem));
    },
    [
      dispatch,
      lineItemId,
      productCategory,
      productId,
      selectedOptions,
      sides,
      lineItemAppDataJson,
      color,
    ],
  );

  const {
    quantity,
    incrementQuantity,
    decrementQuantity,
    changeQuantityHandler,
  } = useQuantity(defaultQuantity, updateLineItem);

  const deleteLineItemHandler = useCallback(async () => {
    handleShowLoader();

    await dispatch(deleteLineItemAsync(lineItemId));

    handleHideLoader();
  }, [dispatch, lineItemId, handleShowLoader, handleHideLoader]);

  const editLineItemHandler = useCallback(() => {
    dispatch(setIsEditLineItemMode(true));

    dispatch(setLineItemId(lineItemId));

    const nextRoute = `${ROUTES.EDITOR}/${toLowerCaseFirstChar(
      productCategoryType || '',
    )}`;

    history.push(nextRoute);
  }, [dispatch, history, lineItemId, productCategoryType]);

  const isSummaryPage =
    history.location.pathname === CHECKOUT_ROUTES.SUMMARY;

  const imageUrl = lineItemImage;

  return (
    <tbody className="cart-body">
      {isMobile && (
        <tr className="cart-tr-image">
          <td>
            <CartImage
              imageUrl={imageUrl}
              alt="CartImage"
              fileName={originalName}
            />
          </td>
        </tr>
      )}
      <tr className="cart-tr">
        <td className="cart-td cart-image">
          {!isMobile && (
            <CartImage
              imageUrl={imageUrl}
              alt="CartImage"
              fileName={originalName}
            />
          )}
          <div className="cart-file">
            <h3 className="cart-file-title isBold">
              {productCategoryDisplayName}
            </h3>
            <SelectedOptionsList
              className="cart-file-text"
              selectedOptions={selectedOptions}
            />
          </div>
        </td>
        {!isMobile && (
          <td className="cart-td cart-price">
            <h3 className="cart-file-title">Item Price</h3>
            <SelectedOptionsPriceList
              selectedOptions={selectedOptions}
            />
          </td>
        )}
        <td className="cart-td cart-sum">
          {isSummaryPage ? (
            <span className="cart-quantity">{defaultQuantity}</span>
          ) : (
            <Counter
              quantity={quantity}
              btnClassNames="cart-quantity-btn"
              inputClassNames="cart-quantity"
              incrementQuantity={incrementQuantity}
              decrementQuantity={decrementQuantity}
              changeQuantityHandler={changeQuantityHandler}
            />
          )}
        </td>
        <td className="cart-td cart-total">{`$ ${lineItemTotal}`}</td>
        {!isSummaryPage && (
          <td className="cart-td">
            <CartItemControls
              deleteLineItemHandler={deleteLineItemHandler}
              editLineItemHandler={editLineItemHandler}
            />
          </td>
        )}
      </tr>
    </tbody>
  );
};

export default CartTableBody;
